import React from 'react'
import { Button } from '@mui/material'
import NotFoundImage from "../../assets/vinaty-not-found.png";
// TODO i18n

const FreeTrialInfo = () => {
  return (
    <div className="results__expired">
    <p className="limit__heading">
      Folders and Bookmarks are not available in Free Trial
    </p>

    <p className="limit__text">
      During Free Trial period the Bookmarks and personal folders are not
      available. However, you can use the main distributor section. To get
      full access, please upgrade your account to full access.
    </p>

    <div className="limit__buttons">
      <Button
        variant="contained"
        size="medium"
        href="/"
      >
        Return to main distributors section
      </Button>

      <Button
        variant="contained"
        size="medium"
        href="https://vinaty.com/account/"
        target="_blank"
      >
        Paid access
      </Button>
    </div>

    <img src={NotFoundImage} width="400" height="229" alt="Not Found" />
  </div>
  )
}

export default FreeTrialInfo