import React from "react";
import CustomProviders from "./hooks";
import { MainNavigation } from "./screens/MainNavigation";

function App() {
  // var salesIqLocal = 'true';

  const isProduction = process.env.NODE_ENV === "production";
  console.log(`Running in ${isProduction ? "production" : "development"} mode`);
  // limitViews

  return (
    <CustomProviders>
      <MainNavigation />
    </CustomProviders>
  );
}

export default App;
