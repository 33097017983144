import React from "react";

import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";
import DownloadingIcon from "@mui/icons-material/Downloading";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import FoldersList from "../FoldersList";
import UserSaves from "../UserSaves";
import i18n from "../../utils/i18n";
import { useFolders } from "../../hooks/FoldersContext";
import { useSaves } from "../../hooks/SavesContext";
import SidebarButton from "./SidebarButton";
import SidebarHeader from "./SidebarHeader";
import { useContent } from "../../hooks/ContentContext";
import { useUser } from "../../hooks/UserContext";

const Sidebar = (props) => {
  const { enableAdminDashboard, bookmarksCounter } = props;
  const { smFoldersOpened, setSmFoldersOpened } = useFolders();
  const { savesOpened, setSavesOpened } = useSaves();
  const { filters, setFilters } = useContent();
  const { userDownloadIds, admin } = useUser();
  // OnClick in Menu Folders
  function openFolders() {
    setSmFoldersOpened((current) => !current);
    if (filters["folder"] !== "") {
      setFilters((prevState) => ({
        ...prevState,
        folder: "",
      }));
    }
  }

  function openSaves() {
    setSavesOpened((current) => !current);
    if (filters["folder"] === "0") {
      setFilters((prevState) => ({
        ...prevState,
        folder: "",
      }));
    }
  }

  function openBookmarks() {
    if (filters["folder"] !== "-1") {
      // setBookmarksOpened((current => !current));
      setFilters((prevState) => ({
        ...prevState,
        folder: "-1",
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        folder: "",
      }));
    }
  }

  function chooseFolder(id_folder) {
    setFilters((prevState) => ({
      ...prevState,
      folder: filters["folder"] === id_folder ? "" : id_folder,
    }));
    if (savesOpened) {
      setSavesOpened(false);
    }
  }

  return (
    <div id="sidebar" className="sidebar">
      <div className="sidebar__gradient">
        <SidebarHeader />

        <div className="sidebar__center">
          <SidebarButton
            title={i18n.t("Main Catalog")}
            subTitle=""
            onClick={() => chooseFolder("")}
            additionalClass={
              filters["folder"] === "" ? " sidebar__button--active" : ""
            }
            startIconIns={<HomeIcon />}
          />

          <SidebarButton
            title={i18n.t("Downloads")}
            subTitle={
              userDownloadIds?.length > 1
                ? " (" + userDownloadIds?.length + ")"
                : ""
            }
            onClick={() => chooseFolder("0")}
            additionalClass={
              filters["folder"] === "0" ? " sidebar__button--active" : ""
            }
            startIconIns={<DownloadingIcon />}
          />

          <SidebarButton
            title={i18n.t("Saved Searches")}
            subTitle=""
            onClick={openSaves}
            additionalClass={"button-saved-search" + (savesOpened ? " sidebar__button--active" : "")}
            startIconIns={<SavedSearchIcon />}
          />

          <SidebarButton
            title={i18n.t("Bookmarks")}
            subTitle={" (" + bookmarksCounter + ")"}
            onClick={openBookmarks}
            additionalClass={
              filters["folder"] === "-1" ? " sidebar__button--active" : ""
            }
            startIconIns={<BookmarkBorderIcon />}
          />
          <SidebarButton
            title={i18n.t("Folders")}
            subTitle=""
            onClick={openFolders}
            startIconIns={<FolderSpecialIcon />}
            endIconIns={<ChevronRightIcon className='end-arrow'/>}
            additionalClass={
              (filters["folder"] !== "-1" &&
              filters["folder"] !== "0" &&
              filters["folder"] !== ""
                ? " sidebar__button--active sidebar__button--opened"
                : "") + (smFoldersOpened ? " sidebar__button--opened" : "")
            }
          />

          <FoldersList />
        </div>

        <UserSaves />

        {admin ? (
          <Button
            className="admin-dashboard-button"
            onClick={enableAdminDashboard}
          >
            <span className="admin-dashboard-button__text">
              Go to Dashboard
            </span>
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default Sidebar;
