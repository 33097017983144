import React, { useState } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const LimitViews = () => {
  const [limitViews, setLimitViews] = useState(false);
  return (
    <div
      className={
        "content__limit-note" +
        (limitViews ? " content__limit-note--active" : "")
      }
    >
      <div className="limit-note__heading">
        <span className="limit-note__h-text">Viewing limit reached</span>
        <span className="limit-note__close">
          <IconButton
            size="large"
            aria-label="delete"
            onClick={() => setLimitViews(false)}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </span>
      </div>
      <span className="limit-note__text">
        Your viewing limit of companies has been reached (2000 views per month).
        You can continue working with the database, but only previously viewed
        companies are accessible to you. Please, contact us at
        support@vinaty.com if you have any questions.
      </span>
    </div>
  );
};

export default LimitViews;
