import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import ReactCountryFlag from "react-country-flag";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";
import Collapse from "@mui/material/Collapse";
// import FiberNewIcon from "@mui/icons-material/FiberNew";
import { useContent } from "../hooks/ContentContext";
import { useCurrency } from "../hooks/CurrencyContext";

const customStyles = {
  control: (base, state) => ({
    ...base,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#CBA3C9" : "#C4C4C4",
    // Removes weird border around container
    boxShadow: 0,
    fontSize: "0.9rem",
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#CBA3C9" : "#CBA3C9",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: "rgb(67, 67, 67)", // Цвет текста для элемента
  }),
};

// const Filters = forwardRef((props, ref) => {
const Filters = (props) => {
  const { Option } = components;

  const CountryOption = (props) => (
    <Option {...props}>
      <ReactCountryFlag
        countryCode={props.data.code}
        svg
        style={{
          width: "1.5em",
          height: "1.5em",
          marginRight: "10px",
        }}
        title={props.data.label}
      />
      {props.data.label}
      <span className="country-counter">{"(" + props.data.counter + ")"}</span>
    </Option>
  );

  const ProvinceOption = (props) => (
    <Option {...props}>
      {props.data.province}
      {<span> | </span>}
      {props.data.country}
    </Option>
  );

  const { setUpdateArrState, setNotificiationState, setDiscountState } = props;
  const { filters, setFilters, setSelectedCountries, selectedCountries } =
    useContent();
  const { setCurrencies } = useCurrency();
  // States
  const [optionsAllState, setOptionsAllState] = useState({}); // All filters from server
  const [filteredCountries, setFilteredCountries] = useState([]); // Filtered list of countries based on regions
  const [filteredProvinces, setFilteredProvinces] = useState([]); // Filtered list of province
  const [countryEmptyState, setCountryEmptyState] = useState(true);
  const [inititalLoad, setInitialLoad] = useState(true); // Initital load state
  const [updateDate, setUpdateDate] = useState("0");
  const [thirdFiltersState, setThirdFiltersState] = useState(false); // Open/Hide Third line of Filters

  const marks = [
    {
      value: 0,
      label: "New",
    },
    {
      value: 20,
      label: "",
    },
    {
      value: 40,
      label: "",
    },
    {
      value: 60,
      label: "",
    },
    {
      value: 80,
      label: "",
    },
    {
      value: 100,
      label: "All",
    },
  ];
  // Date update function
  function valueLabelText(value) {
    var res = "";
    switch (value) {
      case 100:
        res = "All periods";
        break;
      case 80:
        res = "Three months";
        break;
      case 60:
        res = "Two months";
        break;
      case 40:
        res = "Last month";
        break;
      case 20:
        res = "Two weeks";
        break;
      case 0:
        res = "Last week";
        break;
      default:
        res = "";
    }

    return res;
  }

  // Date update function
  function valuetext(value) {
    return value;
  }

  // Final filters which will be send to the server

  useEffect(() => {
    setFilters((prevState) => ({
      ...prevState,
      country: selectedCountries,
    }));
    setCountryEmptyState(false);
    // Dublicate part to filter provinces on save load
    if (selectedCountries?.length > 0) {
      setCountryEmptyState(false);
    } else {
      setCountryEmptyState(true);
    }
    var tempProvincesArray = [];
    for (let i = 0; i < selectedCountries?.length; i++) {
      for (
        let province_i = 0;
        province_i < optionsAllState["provinces"]?.length;
        province_i++
      ) {
        if (
          optionsAllState["provinces"][province_i]["country"] ===
          selectedCountries[i]["label"]
        ) {
          tempProvincesArray.push(optionsAllState["provinces"][province_i]);
        }
      }
    }
    setFilteredProvinces(tempProvincesArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountries]);

  useEffect(() => {
    if (filters.province?.length > 0) {
      var tempChosenProvinces = [];
      for (let i = 0; i < filters.province?.length; i++) {
        for (
          let province_i = 0;
          province_i < filteredProvinces?.length;
          province_i++
        ) {
          if (
            filters.province[i]["province"] ===
            filteredProvinces[province_i]["province"]
          ) {
            tempChosenProvinces.push(filters.province[i]);
          }
        }
      }
      setFilters((prevState) => ({
        ...prevState,
        province: tempChosenProvinces,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredProvinces]);

  // LOAD SAVE help func -> Update initial Selected countries with filters->county when filters loaded
  useEffect(() => {
    if (inititalLoad && filters !== undefined && filters.country?.length > 0) {
      setInitialLoad(false);
      setSelectedCountries(filters.country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // vars
  var urlApi;

  //\\//\\// Request available filters \\//\\//\\

  function requestFilters() {
    urlApi = process.env.REACT_APP_URL_API + "distributors";

    const queryDistributors = {
      data: "filters",
    };
    fetch(urlApi, {
      method: "POST",
      headers: {
        Authorization: process.env.REACT_APP_TOKEN_API,
      },
      cache: "no-store",
      body: JSON.stringify(queryDistributors),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setOptionsAllState(data);
        // console.log(data);
        setFilteredCountries(data["countries"]); // Because data to countries doesn't go directly
        setUpdateArrState(data["updates"]);
        setNotificiationState(data["notifications"]); // Admin news
        setDiscountState(data["advertise"]);
        const currencies = data["currencies"][0]?.value?.split(",");
        const currencyObject = currencies.reduce((acc, item) => {
          const [key, value] = item.split(":");
          acc[key] = parseFloat(value);
          return acc;
        }, {});
        setCurrencies(currencyObject);
        // Renew currencies IF !!!
      })
      .catch((error) => {
        // window.location.replace("https://vinaty.com/login/");
        // alert('Something went wrong during loading of the content. Error code 002');
      });
  }

  // On first load
  useEffect(() => {
    requestFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //\\//\\ All functions about changing of region and filtering countires //\\//\\//

  // Change the list of available countries according to chosen region
  function updateCountries() {
    if (
      optionsAllState["countries"] !== undefined &&
      optionsAllState["countries"] !== "undefined"
    ) {
      if (filters.region?.length > 0) {
        var tempFilteredCountries = [];
        for (let i = 0; i < optionsAllState["countries"]?.length; i++) {
          for (
            let region_i = 0;
            region_i < filters.region?.length;
            region_i++
          ) {
            if (
              optionsAllState["countries"][i]["region"] ===
              filters.region[region_i]["value"]
            ) {
              tempFilteredCountries.push(optionsAllState["countries"][i]);
            }
          }
        }
        setFilteredCountries(tempFilteredCountries);
      } else {
        setFilteredCountries(optionsAllState["countries"]);
      }

      // Remove countries from the chosen country list which doesn't match to the chosen region
      if (filters.region?.length > 0) {
        var tempChosenCountries = [];
        for (let i = 0; i < selectedCountries?.length; i++) {
          for (
            let country_i = 0;
            country_i < filters.region.length;
            country_i++
          ) {
            if (
              selectedCountries[i]["region"] ===
              filters.region[country_i]["value"]
            ) {
              tempChosenCountries.push(selectedCountries[i]);
            }
          }
        }
        setSelectedCountries(tempChosenCountries);
      }
    }
  }

  // Set chosen REGIONS / FINAL
  function regionHandler(event) {
    var tempArray = [];
    for (let i = 0; i < event?.length; i++) {
      tempArray.push(event[i]);
    }
    setFilters((prevState) => ({
      ...prevState,
      region: tempArray,
    }));
  }

  // When regions in filters change - init update of country list
  useEffect(() => {
    updateCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.region]);

  const countryHandler = (selectedCountries) => {
    setSelectedCountries(selectedCountries);
  };

  // sizesHandler
  function sizesHandler(event) {
    var tempArraySizes = [];
    for (let i = 0; i < event?.length; i++) {
      // tempArraySizes.push(event[i].value);
      tempArraySizes.push(event[i]);
    }
    // setSelectedSizes(tempArraySizes);
    setFilters((prevState) => ({
      ...prevState,
      size: tempArraySizes,
    }));
  }

  // typesHandler
  function productsHandler(event) {
    var tempArrayTypes = [];
    for (let i = 0; i < event?.length; i++) {
      tempArrayTypes.push(event[i]);
    }
    // setSelectedTypes(tempArrayTypes);
    setFilters((prevState) => ({
      ...prevState,
      products: tempArrayTypes,
    }));
  }

  // typesHandler
  function typesHandler(event) {
    var tempArrayTypes = [];
    for (let i = 0; i < event?.length; i++) {
      tempArrayTypes.push(event[i]);
    }
    // setSelectedTypes(tempArrayTypes);
    setFilters((prevState) => ({
      ...prevState,
      type: tempArrayTypes,
    }));
  }

  // provincesHandler
  function provincesHandler(event) {
    var tempArrayProvinces = [];
    for (let i = 0; i < event?.length; i++) {
      // tempArrayProvinces.push(event[i].province);
      tempArrayProvinces.push(event[i]);
    }
    // setSelectedProvinces(tempArrayProvinces);
    setFilters((prevState) => ({
      ...prevState,
      province: tempArrayProvinces,
    }));
  }

  // producingCountriesHandler
  function producingCountriesHandler(event) {
    var tempArrayProducingCountries = [];
    for (let i = 0; i < event?.length; i++) {
      tempArrayProducingCountries.push(event[i]);
    }
    // setProducingCountries(tempArrayProducingCountries);
    setFilters((prevState) => ({
      ...prevState,
      prod_country: tempArrayProducingCountries,
    }));
  }
  // Change STRICT producingCountriesHandler
  const strictProdCountriesHandler = () => {
    if (filters.prod_strict === false) {
      setFilters((prevState) => ({
        ...prevState,
        prod_strict: true,
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        prod_strict: false,
      }));
    }
  };

  function dateUpdate(event, value) {
    let filt = "0";
    if (value === 100) {
      filt = "0";
    } else if (value === 80) {
      filt = "12";
    } else if (value === 60) {
      filt = "8";
    } else if (value === 40) {
      filt = "4";
    } else if (value === 20) {
      filt = "2";
    } else if (value === 0) {
      filt = "1";
    }

    setFilters((prevState) => ({
      ...prevState,
      time_updated: filt,
    }));
    setUpdateDate(filt);
  }

  // Open or Hide Third line filters
  function openThirdLineFilters() {
    setThirdFiltersState((thirdFiltersState) => !thirdFiltersState);
    setFilters((prevState) => ({
      ...prevState,
      years: [],
      employees: [],
      prodq: [],
      tier: [],
    }));
  }

  // Third line handlers

  // Years Handler
  function yearsHandler(event) {
    var tempArrayYears = [];
    for (let i = 0; i < event?.length; i++) {
      tempArrayYears.push(event[i]);
    }
    // setSelectedTypes(tempArrayTypes);
    setFilters((prevState) => ({
      ...prevState,
      years: tempArrayYears,
    }));
  }

  // Employees Handler
  function employeesHandler(event) {
    var tempArrayEmployees = [];
    for (let i = 0; i < event?.length; i++) {
      tempArrayEmployees.push(event[i]);
    }
    // setSelectedTypes(tempArrayTypes);
    setFilters((prevState) => ({
      ...prevState,
      employees: tempArrayEmployees,
    }));
  }

  // Product Quantity Handler
  function prodqHandler(event) {
    var tempArrayProdq = [];
    for (let i = 0; i < event?.length; i++) {
      tempArrayProdq.push(event[i]);
    }
    // setSelectedTypes(tempArrayTypes);
    setFilters((prevState) => ({
      ...prevState,
      prodq: tempArrayProdq,
    }));
  }

  // Tier Handler
  function tierHandler(event) {
    var tempArrayTier = [];
    for (let i = 0; i < event?.length; i++) {
      tempArrayTier.push(event[i]);
    }
    // setSelectedTypes(tempArrayTypes);
    setFilters((prevState) => ({
      ...prevState,
      tier: tempArrayTier,
    }));
  }

  // Rendering
  return (
    <div className={"filters content__block"}>
      <div className="filters__top filters-container">
        <div
          className={
            "filters__item filter-update" +
            (filters.time_updated !== "0" ? " filters__item--active" : "")
          }
        >
          <Box>
            <Slider
              id="date-slider"
              className="filter-update__slider"
              aria-label="Date Update"
              defaultValue={100}
              getAriaValueText={valuetext}
              valueLabelFormat={valueLabelText}
              // onChangeCommitted={(value)=>dateUpdate(value)}
              // onChangeCommitted={(event, value) => dateUpdate(value)}
              onChangeCommitted={dateUpdate}
              step={20}
              marks={marks}
              valueLabelDisplay={updateDate === "0" ? "auto" : "on"}
              color="secondary"
            />
          </Box>
        </div>

        <Select
          className={
            "filters__item filter-region" +
            (filters.region?.length > 0 ? " filters__item--active" : "")
          }
          classNamePrefix="filter-select"
          closeMenuOnSelect={false}
          isMulti
          options={optionsAllState["regions"]}
          onChange={(event) => regionHandler(event)}
          // defaultValue={[]}
          value={filters.region}
          def
          styles={customStyles} // pass the customStyles object to the styles prop
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "rgba(141, 82, 137, 0.10)",
              primary50: "rgba(141, 82, 137, 0.25)",
            },
          })}
        />

        <Select
          className={
            "filters__item filter-country" +
            (filters.country?.length > 0 ? " filters__item--active" : "")
          }
          styles={customStyles}
          classNamePrefix="filter-select"
          closeMenuOnSelect={false}
          components={{ Option: CountryOption }}
          // defaultValue={''}
          value={selectedCountries}
          isMulti
          options={filteredCountries}
          onChange={(event) => countryHandler(event)}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "rgba(141, 82, 137, 0.10)",
              primary50: "rgba(141, 82, 137, 0.25)",
            },
          })}
        />

        <Select
          className={
            "filters__item filter-province" +
            (filters.province?.length > 0 ? " filters__item--active" : "")
          }
          styles={customStyles}
          classNamePrefix="filter-select"
          closeMenuOnSelect={false}
          components={{ Option: ProvinceOption }}
          isMulti
          options={filteredProvinces}
          getOptionLabel={(option) => option["province"]}
          getOptionValue={(option) => option["province"]}
          onChange={(event) => provincesHandler(event)}
          isDisabled={countryEmptyState || filteredProvinces?.length === 0}
          placeholder={
            countryEmptyState ? (
              <span>Choose a country</span>
            ) : filteredProvinces?.length > 0 ? (
              <span>Select...</span>
            ) : (
              <span>No provinces available</span>
            )
          }
          // defaultValue={''}
          value={filters.province}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "rgba(141, 82, 137, 0.10)",
              primary50: "rgba(141, 82, 137, 0.25)",
            },
          })}
        />
      </div>

      <div className="filters__bottom filters-container">
        <div className="filters__item" style={{ position: "relative" }}>
          <Select
            className={
              "filters__item filter-size" +
              (filters.size?.length > 0 ? " filters__item--active" : "")
            }
            styles={customStyles}
            classNamePrefix="filter-select"
            closeMenuOnSelect={false}
            isMulti
            options={optionsAllState["sizes"]}
            onChange={(event) => sizesHandler(event)}
            // defaultValue={''}
            value={filters.size}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "rgba(141, 82, 137, 0.10)",
                primary50: "rgba(141, 82, 137, 0.25)",
              },
            })}
          />
        </div>

        <div className="filters__item" style={{ position: "relative" }}>
          <Select
            className={
              "filters__item filter-type" +
              (filters.type?.length > 0 ? " filters__item--active" : "")
            }
            styles={customStyles}
            classNamePrefix="filter-select"
            closeMenuOnSelect={false}
            isMulti
            options={optionsAllState["types"]}
            onChange={(event) => typesHandler(event)}
            // defaultValue={''}
            value={filters.type}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "rgba(141, 82, 137, 0.10)",
                primary50: "rgba(141, 82, 137, 0.25)",
              },
            })}
          />
        </div>

        <div className="filters__item" style={{ position: "relative" }}>
          <Select
            className={
              "filters__item filter-product" +
              (filters.products?.length > 0 ? " filters__item--active" : "")
            }
            styles={customStyles}
            classNamePrefix="filter-select"
            closeMenuOnSelect={false}
            isMulti
            options={optionsAllState["products"]}
            onChange={(event) => productsHandler(event)}
            value={filters.products}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "rgba(141, 82, 137, 0.10)",
                primary50: "rgba(141, 82, 137, 0.25)",
              },
            })}
          />
        </div>

        <div className="filters__item" style={{ position: "relative" }}>
          <FormControlLabel
            label="Specializing in these countries only"
            className={
              "form-control--strict" +
              (filters.prod_country?.length > 0
                ? ""
                : " form-control--inactive")
            }
            control={
              <Checkbox
                disabled={filters.prod_country?.length > 0 ? false : true}
                checked={filters.prod_strict}
                onChange={strictProdCountriesHandler}
                name="strict_prod_check"
              />
            }
          />

          <Select
            className={
              "filters__item filter-imported-countries" +
              (filters.prod_country?.length > 0 ? " filters__item--active" : "")
            }
            styles={customStyles}
            classNamePrefix="filter-select"
            closeMenuOnSelect={false}
            isMulti
            options={optionsAllState["producing_countries"]}
            onChange={(event) => producingCountriesHandler(event)}
            // defaultValue={''}
            value={filters.prod_country}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "rgba(141, 82, 137, 0.10)",
                primary50: "rgba(141, 82, 137, 0.25)",
              },
            })}
          />
        </div>
      </div>
      <div className="filters__third-outer">
        <div className="third-outer__heading">
          {/* <span className="inline-icon">
            <FiberNewIcon />
          </span> */}
          <FormControlLabel
            label={
              !thirdFiltersState
                ? "Open additional filters"
                : "Hide additional filters"
            }
            labelPlacement="start"
            control={
              <Switch
                checked={thirdFiltersState}
                onChange={openThirdLineFilters}
                size="large"
                name="third-line-filters"
              />
            }
          />
        </div>

        <Collapse
          className="filters__third-inner-collapse"
          in={thirdFiltersState}
          timeout="auto"
          unmountOnExit
        >
          <div className="filters__third-inner filters-container filters-container--third">
            <div className="filters__item" style={{ position: "relative" }}>
              <Select
                className={
                  "filter-year" +
                  (filters.years?.length > 0 ? " filters__item--active" : "")
                }
                styles={customStyles}
                classNamePrefix="filter-select"
                closeMenuOnSelect={false}
                isMulti
                options={optionsAllState["years"]}
                onChange={(event) => yearsHandler(event)}
                // defaultValue={''}
                value={filters.years}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "rgba(141, 82, 137, 0.10)",
                    primary50: "rgba(141, 82, 137, 0.25)",
                  },
                })}
              />
            </div>

            <div className="filters__item" style={{ position: "relative" }}>
              <Select
                className={
                  "filter-employees" +
                  (filters.employees?.length > 0
                    ? " filters__item--active"
                    : "")
                }
                styles={customStyles}
                classNamePrefix="filter-select"
                closeMenuOnSelect={false}
                isMulti
                options={optionsAllState["employees"]}
                onChange={(event) => employeesHandler(event)}
                // defaultValue={''}
                value={filters.employees}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "rgba(141, 82, 137, 0.10)",
                    primary50: "rgba(141, 82, 137, 0.25)",
                  },
                })}
              />
            </div>

            <div className="filters__item" style={{ position: "relative" }}>
              <Select
                className={
                  "filter-prodq" +
                  (filters.prodq?.length > 0 ? " filters__item--active" : "")
                }
                styles={customStyles}
                classNamePrefix="filter-select"
                closeMenuOnSelect={false}
                isMulti
                options={optionsAllState["prodq"]}
                onChange={(event) => prodqHandler(event)}
                // defaultValue={''}
                value={filters.prodq}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "rgba(141, 82, 137, 0.10)",
                    primary50: "rgba(141, 82, 137, 0.25)",
                  },
                })}
              />
            </div>

            <div className="filters__item" style={{ position: "relative" }}>
              <Select
                className={
                  "filter-tier" +
                  (filters.tier?.length > 0 ? " filters__item--active" : "")
                }
                styles={customStyles}
                classNamePrefix="filter-select"
                closeMenuOnSelect={false}
                isMulti
                options={optionsAllState["tier"]}
                onChange={(event) => tierHandler(event)}
                // defaultValue={''}
                value={filters.tier}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "rgba(141, 82, 137, 0.10)",
                    primary50: "rgba(141, 82, 137, 0.25)",
                  },
                })}
              />
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default Filters;
