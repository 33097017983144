import { getToken } from "../utils";
import Api from "../utils/api";

export async function handleFetchUser(props) {
  try {
    const response = await Api.post("auth", {
      data: "token",
      token: getToken(),
      ...props,
    });
    return response.data;
  } catch (error) {
    alert("Something went wrong during save of the content. Error code u001");
    window.location.replace("https://vinaty.com/login/");
    console.error(error);
  }
}
