import React, { useEffect, useState } from "react";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import HelpIcon from "@mui/icons-material/Help";
import UnsubscribeSharpIcon from "@mui/icons-material/UnsubscribeSharp";
import { Fade, LinearProgress, Stack, Tooltip } from "@mui/material";
import { convertDate } from "../../../utils";
import { CopyIcon } from "../../CopyIcon";
import EmailIcon from "@mui/icons-material/Email";

const CompanyEmail = ({ fetching, openEmailStatusInfo, companyData }) => {
  const [emails, setEmails] = useState([]);
  useEffect(() => {
    if (companyData) {
      setEmails(companyData["emails"]);
    }
  }, [companyData]);

  return (
    <>
      {fetching ? (
        <div className={"company__section company__section-emails fade-appear"}>
          <div className="company__emails-h company__heading">
            Emails |
            <div
              className={"company__question-h"}
              onClick={(event) => openEmailStatusInfo(event)}
            >
              <span> Status</span>

              <HelpIcon
                className="question-toggle"
                sx={{ fontSize: 20 }}
                onClick={(event) => openEmailStatusInfo(event)}
              />
            </div>
          </div>

          {emails?.length > 0 ? (
            <div className="emails__list">
              {emails?.map((el, index) => {
                return (
                  <div className="emails__email" key={"em" + index}>
                    <div
                      className="emails__status-container"
                      onClick={(event) => openEmailStatusInfo(event)}
                    >
                      {el.email_status === "valid" ? (
                        <Tooltip
                          placement="left"
                          size="small"
                          title={
                            <span className="info-span">
                              Email checked on {convertDate(el.status_date)}
                            </span>
                          }
                        >
                          <span className="emails__status emails__status--verified">
                            <MarkEmailReadIcon /> Verified
                          </span>
                        </Tooltip>
                      ) : el.email_status === "invalid" ? (
                        <Tooltip
                          placement="left"
                          size="small"
                          title={
                            <span className="info-span">
                              Email checked on {convertDate(el.status_date)}
                            </span>
                          }
                        >
                          <span className="emails__status emails__status--inactive">
                            <UnsubscribeSharpIcon /> Inactive
                          </span>
                        </Tooltip>
                      ) : el.email_status === "do_not_mail" &&
                        (el.status_details === "role_based_catch_all" ||
                          el.status_details === "role_based") ? (
                        <Tooltip
                          placement="left"
                          size="small"
                          title={
                            <span className="info-span">
                              Email checked on {convertDate(el.status_date)}
                            </span>
                          }
                        >
                          <span className="emails__status emails__status--verified">
                            <MarkEmailReadIcon /> Role-based
                          </span>
                        </Tooltip>
                      ) : el.email_status === "do_not_mail" &&
                        (el.status_details === "global_suppression" ||
                          el.status_details === "toxic" ||
                          el.status_details === "possible_trap") ? (
                        <Tooltip
                          placement="left"
                          size="small"
                          title={
                            <span className="info-span">
                              Email checked on {convertDate(el.status_date)}
                            </span>
                          }
                        >
                          <span className="emails__status emails__status--inactive">
                            <DoNotDisturbIcon /> Do not use
                          </span>
                        </Tooltip>
                      ) : el.email_status === "do_not_mail" &&
                        (el.status_details === "mx_forward" ||
                          el.status_details === "disposable") ? (
                        <Tooltip
                          placement="left"
                          size="small"
                          title={
                            <span className="info-span">
                              Email checked on {convertDate(el.status_date)}
                            </span>
                          }
                        >
                          <span className="emails__status emails__status--verified--yellow">
                            <MarkEmailReadIcon /> Redirect?
                          </span>
                        </Tooltip>
                      ) : el.email_status === "not_checked" ? (
                        <Tooltip
                          placement="left"
                          size="small"
                          title={
                            <span className="info-span">
                              Email checked on {convertDate(el.status_date)}
                            </span>
                          }
                        >
                          <span className="emails__status emails__status--notchecked">
                            <HourglassTopIcon /> Under review
                          </span>
                        </Tooltip>
                      ) : el.email_status === "catch-all" ? (
                        <Tooltip
                          placement="left"
                          size="small"
                          title={
                            <span className="info-span">
                              Email checked on {convertDate(el.status_date)}
                            </span>
                          }
                        >
                          <span className="emails__status emails__status--verified--yellow">
                            <MarkEmailReadIcon /> Catch-all
                          </span>
                        </Tooltip>
                      ) : el.email_status === "abuse" ? (
                        <Tooltip
                          placement="left"
                          size="small"
                          title={
                            <span className="info-span">
                              Email checked on {convertDate(el.status_date)}
                            </span>
                          }
                        >
                          <span className="emails__status emails__status--verified--yellow">
                            <MarkEmailReadIcon /> Abuse
                          </span>
                        </Tooltip>
                      ) : el.email_status === "unknown" &&
                        (el.status_details === "antispam_system" ||
                          el.status_details === "greylisted") ? (
                        <Tooltip
                          placement="left"
                          size="small"
                          title={
                            <span className="info-span">
                              Email checked on {convertDate(el.status_date)}
                            </span>
                          }
                        >
                          <span className="emails__status emails__status--verified--yellow">
                            <MarkEmailReadIcon /> Antispam
                          </span>
                        </Tooltip>
                      ) : el.email_status === "unknown" &&
                        (el.status_details === "failed_smtp_connection" ||
                          el.status_details ===
                            "mail_server_temporary_error") ? (
                        <Tooltip
                          placement="left"
                          size="small"
                          title={
                            <span className="info-span">
                              Email checked on {convertDate(el.status_date)}
                            </span>
                          }
                        >
                          <span className="emails__status emails__status--notchecked">
                            <HourglassTopIcon /> Unknown
                          </span>
                        </Tooltip>
                      ) : el.email_status === "unknown" ? (
                        <Tooltip
                          placement="left"
                          size="small"
                          title={
                            <span className="info-span">
                              Email checked on {convertDate(el.status_date)}
                            </span>
                          }
                        >
                          <span className="emails__status emails__status--notchecked">
                            <HourglassTopIcon /> Unknown
                          </span>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </div>
                    <CopyIcon left={false} value={el.email} />
                    <span className={"emails__text-email"}>{el.email}</span>
                  </div>
                );
              })}
            </div>
          ) : fetching && emails?.length === 0 ? (
            <span className="single-bottom-info__no-info no-info--email">
              <EmailIcon />
              No emails found
            </span>
          ) : null}
        </div>
      ) : (
        <Fade
          unmountOnExit={true}
          in={true}
          timeout={{ enter: 400, exit: 200 }}
        >
          <span className="wide-loader-item wide-loader-item--full">
            <Stack sx={{ width: "100%", color: "grey.500" }} spacing={4}>
              <LinearProgress
                color="primary_grey_light_light"
                sx={{
                  height: "25px",
                  borderRadius: "2px",
                }}
              />
            </Stack>
          </span>
        </Fade>
      )}
    </>
  );
};

export default CompanyEmail;
