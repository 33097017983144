import { Button, Fade, LinearProgress, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaFolder } from "react-icons/fa";
import FoldersAction from "../../Pagination/FoldersAction";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { findIcon } from "../../../utils";
import DownloadIcon from "@mui/icons-material/Download";
import { useFolders } from "../../../hooks/FoldersContext";
import { reportData } from "../../../actions/content";

const CompanyFolders = ({ fetching, companyData, exportThisCompany }) => {
  const [arrayFoldersItem, setArrayFoldersItem] = useState([]);
  const [reported, setReported] = useState(false);
  const { savedFolders } = useFolders();
  const { id } = companyData;

  function checkFolderItem() {
    var preArrayFolderItem = [];
    for (let i = 0; i < savedFolders?.length; i++) {
      var folders = savedFolders[i].content.split(",");
      for (let f = 0; f < folders?.length; f++) {
        if (folders[f] === id) {
          preArrayFolderItem.push(savedFolders[i]);
        }
      }
    }
    setArrayFoldersItem(preArrayFolderItem);
  }

  useEffect(() => {
    checkFolderItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedFolders]);

  function reportInnacuracy() {
    const params = {
      item_id: id,
    };
    reportData(params).then((data) => {
      if (data["success"] === "1") {
        setReported(true);
      } else {
        setReported("error");
        setTimeout(() => {
          setReported(false);
        }, 2200);
      }
    });
  }

  return (
    <>
      {fetching ? (
        <>
          <div className="company__action--folders fade-appear">
            <FoldersAction item={companyData} typeActionButton={2} />
          </div>

          <div
            className={
              "fade-appear company__btm-folders" +
              (arrayFoldersItem?.length < 1
                ? " company__btm-folders--empty"
                : "")
            }
          >
            {arrayFoldersItem?.length > 0 ? (
              arrayFoldersItem?.map((itemD, index) => {
                return (
                  <span key={"folder" + index}>
                    {findIcon(itemD)}
                    {itemD.name}
                    {arrayFoldersItem.length > index &&
                    index + 1 < arrayFoldersItem.length &&
                    arrayFoldersItem.length !== 1
                      ? ", "
                      : ""}
                  </span>
                );
              })
            ) : (
              <>
                <FaFolder />
                Not added to folders
              </>
            )}
          </div>

          <div className="company__btm-actions fade-appear">
            <Button
              onClick={exportThisCompany}
              size={"small"}
              color={"primary_grey_light"}
              variant="contained"
              className=""
              startIcon={<DownloadIcon />}
              sx={{
                textTransform: "none",
                borderRadius: "2px",
                fontSize: "0.86rem",
              }}
            >
              Download Company
            </Button>
          </div>

          <div className="company__btm-report fade-appear">
            {!reported ? (
              <Button
                variant="text"
                endIcon={<NotificationsActiveIcon />}
                onClick={reportInnacuracy}
              >
                Report Inaccuracy
              </Button>
            ) : reported === "error" ? (
              <Button disabled variant="text">
                Error
              </Button>
            ) : (
              <Button
                disabled
                endIcon={<NotificationImportantIcon />}
                variant="text"
              >
                Reported
              </Button>
            )}
          </div>
        </>
      ) : (
        <Fade
          unmountOnExit={true}
          in={true}
          timeout={{ enter: 400, exit: 200 }}
        >
          <span className="wide-loader-item wide-loader-item--full wide-loader-item--double">
            <Stack sx={{ width: "100%", color: "grey.500" }} spacing={4}>
              <LinearProgress
                color="primary_grey_light_light"
                sx={{
                  height: "25px",
                  borderRadius: "2px",
                }}
              />
              <LinearProgress
                color="primary_grey_light_light"
                sx={{
                  height: "25px",
                  borderRadius: "2px",
                }}
              />
            </Stack>
          </span>
        </Fade>
      )}
    </>
  );
};

export default CompanyFolders;
