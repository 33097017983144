import React from "react";
import logoPng from "../../assets/vinaty-logo.png";
import { Box, CircularProgress } from "@mui/material";

const MainLoader = () => {
  return (
    <div className="preloader">
      <div className="preloader__logo">
        <img src={logoPng} alt="Vinaty Logo" />
      </div>

      <Box className="no-access__circle-loader" sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    </div>
  );
};

export default MainLoader;
