import React from "react";
import MainLoader from "../../components/MainLoader";

const ClosedStorefront = ({ wrongToken }) => {
  return (
    <div className="preloader">
      <MainLoader />
      {!wrongToken ? (
        <div className="no-access__text">
          <span>Checking permissions. Please wait...</span>
        </div>
      ) : null}
    </div>
  );
};

export default ClosedStorefront;
