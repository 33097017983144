import { Fade, LinearProgress, Stack } from "@mui/material";
import React from "react";
import { FaBottleDroplet } from "react-icons/fa6";
import { IoIosWine } from "react-icons/io";
import { LiaGlassWhiskeySolid } from "react-icons/lia";
import { PiBeerBottleFill } from "react-icons/pi";
import { RiBeerLine } from "react-icons/ri";
import YardIcon from "@mui/icons-material/Yard";
import SpaIcon from "@mui/icons-material/Spa";

const CompanyProducts = ({ fetching, companyData }) => {
  return (
    <>
      {fetching && companyData.spec?.length > 0 ? (
        <div className={"company__section-btm-products fade-appear"}>
          <div className="company__btm-products-h company__heading">
            Products
          </div>

          <div className="company__btm-products">
            {/* <MdOutlineSell /> */}
            {companyData.spec?.length > 0
              ? companyData.spec.split(",").map((item_type, index) => {
                  if (item_type === "w") {
                    return (
                      <span
                        key={"w" + item_type + index}
                        className="btm-products__item btm-products__item--wine"
                      >
                        <IoIosWine />
                        <span>Wine</span>
                      </span>
                    );
                  } else if (item_type === "s") {
                    return (
                      <span
                        key={"s" + item_type + index}
                        className="btm-products__item btm-products__item--spirits"
                      >
                        <LiaGlassWhiskeySolid />
                        <span>Spirits</span>
                      </span>
                    );
                  } else if (item_type === "b") {
                    return (
                      <span
                        key={"b" + item_type + index}
                        className="btm-products__item btm-products__item--beer"
                      >
                        <RiBeerLine />
                        <span>Beer</span>
                      </span>
                    );
                  } else if (item_type === "c") {
                    return (
                      <span
                        key={"c" + item_type + index}
                        className="btm-products__item btm-products__item--cider"
                      >
                        <PiBeerBottleFill />
                        <span>Cider</span>
                      </span>
                    );
                  } else if (item_type === "o") {
                    return (
                      <span
                        key={"o" + item_type + index}
                        className="btm-products__item btm-products__item--oil"
                      >
                        <FaBottleDroplet />
                        <span>Oil</span>
                      </span>
                    );
                  } else if (item_type === "wo") {
                    return (
                      <span
                        key={"wo" + item_type + index}
                        className="btm-products__item btm-products__item--bio"
                      >
                        <SpaIcon />
                        <span>Organic Wine</span>
                      </span>
                    );
                  } else {
                    return null;
                  }
                })
              : null}
            {companyData.bio === "1" ? (
              <div className="btm-products__item btm-products__item--bio-line">
                <YardIcon />
                <span>Organic &#124; Bio &#124; Natural</span>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <Fade
          unmountOnExit={true}
          in={true}
          timeout={{ enter: 400, exit: 200 }}
        >
          <span className="wide-loader-item wide-loader-item--full">
            <Stack sx={{ width: "100%", color: "grey.500" }} spacing={4}>
              <LinearProgress
                color="primary_grey_light_light"
                sx={{
                  height: "25px",
                  borderRadius: "2px",
                }}
              />
            </Stack>
          </span>
        </Fade>
      )}
    </>
  );
};

export default CompanyProducts;
