import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loader() {
  return (
    <div className="loader__container">
      <CircularProgress color="primary" />
    </div>
  );
}
