import axios from "axios";

// Config axios defaults.
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
  timeout: 100000,
});

AxiosInstance.interceptors.request.use(async (conf) => {
  const newConf = { ...conf };
  newConf.params = conf.params || {};
  // newConf.params.token = getToken();
  newConf.headers.Authorization = process.env.REACT_APP_TOKEN_API;
  newConf.headers['Content-Type'] = 'application/json';
  return newConf;
});

AxiosInstance.interceptors.response.use(
  (config) => config,
  (error) => {
    if (error?.response?.status === 401) {
      return error?.response?.status;
    } else if (
      error?.response?.status === 408 ||
      error?.code === "ECONNABORTED"
    ) {
      console.error(`A time happend on url ${error.config.url}`);
      return error?.response?.status;
    } else if (error?.response?.status === 404) {
      console.log(error?.response);
      return error?.response?.status;
    } else if (error?.response?.status === 403) {
      return error?.response?.status;
    } else if (error?.response?.status === 400) {
      console.error(error?.response);
      return error?.response;
    }
    console.log(error)
    return Promise.reject(error);
  }
);

export default AxiosInstance;
