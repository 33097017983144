import { getToken } from "../utils";
import Api from "../utils/api";

export async function handleCreateFolder(props) {
  // TODO

  try {
    const response = await Api.post("folders", {
      data: "new_folder",
      token: getToken(),
      ...props,
    });
    return response.data;
  } catch (error) {
    alert("Something went wrong during save of the content. Error code f001");
    console.error(error);
  }
}

export async function handleDeleteFolder(params) {
  // TODO
  try {
    const response = await Api.post("folders", {
      data: "delete_folder",
      token: getToken(),
      ...params,
    });
    return response.data;
  } catch (error) {
    alert("Something went wrong during save of the content. Error code f003");
    window.location.replace("https://vinaty.com/login/");
    console.error(error);
  }
}

export async function handleAddItemToFolder(props) {
  try {
    const response = await Api.post("folders", {
      data: "item_to_folder",
      token: getToken(),
      ...props,
    });
    return response.data;
  } catch (error) {
    alert("Something went wrong during save of the content. Error code p004");
    console.error(error);
  }
}

export async function handleAddBookmark(params) {
  try {
    const response = await Api.post("folders", {
      data: "save_bookmark",
      token: getToken(),
      ...params,
    });
    return response.data;
  } catch (error) {
    alert("Something went wrong during save of the content. Error code p005");
    console.error(error);
  }
}