import React, { createContext, useState, useContext } from "react";

const FoldersContext = createContext();

export const FoldersProvider = ({ children }) => {
  const [savedFolders, setSavedFolders] = useState([]);
  const [smFoldersOpened, setSmFoldersOpened] = useState(true);
  return (
    <FoldersContext.Provider
      value={{
        savedFolders,
        setSavedFolders,
        smFoldersOpened,
        setSmFoldersOpened,
      }}
    >
      {children}
    </FoldersContext.Provider>
  );
};

export const useFolders = () => {
  return useContext(FoldersContext);
};
