import React, { useState } from "react";
import {
  Button,
  ClickAwayListener,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { useFolders } from "../../hooks/FoldersContext";
import BackspaceIcon from "@mui/icons-material/Backspace";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { findIcon } from "../../utils";
import i18n from "../../utils/i18n";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import { useContent } from "../../hooks/ContentContext";

const FoldersButton = ({ contentMenuHide, addItemToFolder, action }) => {
  const { savedFolders } = useFolders();
  const { chosenItems } = useContent();
  const [openRemove, setOpenRemove] = useState(false);

  const handleClickRemove = (event) => {
    if (openRemove === false) {
      setOpenRemove(true);
    } else if (openRemove === true) {
      setOpenRemove(false);
    }
  };

  function animateFolderAction(folderId, action) {
    var targetFolder = document.querySelector(
      '[data-folder="' + folderId + '"]'
    );

    var buttonClick;

    if (action === "add") {
      buttonClick = document.getElementById("folder-add-container-" + folderId);
    } else if (action === "remove") {
      buttonClick = document.getElementById(
        "folder-remove-container-" + folderId
      );
    }
    setTimeout(() => {
      buttonClick.querySelector(".folder-action-span").style.display = "flex";
    }, 200);

    setTimeout(() => {
      targetFolder.classList.add("folders__item--blink");
      buttonClick
        .querySelector(".folder-action-span")
        .classList.add("folder-action-span--blink");
    }, 300);

    setTimeout(() => {
      targetFolder.classList.remove("folders__item--blink");
      buttonClick
        .querySelector(".folder-action-span")
        .classList.remove("folder-action-span--blink");
    }, 2500);

    setTimeout(() => {
      buttonClick.querySelector(".folder-action-span").style.display = "none";
    }, 3000);
  }

  function clickAwayMultibarRemove() {
    if (openRemove === true) {
      setOpenRemove(false);
    }
  }
  return (
    <Tooltip
      title={
        savedFolders.length < 1
          ? i18n.t("TextNoFolders")
          : chosenItems.length < 1
          ? i18n.t("TextNoCompany")
          : ""
      }
    >
      <div className="folder-multi-actions__remove">
        <Button
          disabled={
            contentMenuHide || chosenItems.length < 1 || savedFolders.length < 1
          }
          onClick={() => handleClickRemove()}
          className="content-menu__button"
          variant="contained"
          color={!openRemove ? "primary_grey_light" : "primary"}
          startIcon={
            action === "add" ? <DriveFileMoveIcon /> : <BackspaceIcon />
          }
          endIcon={openRemove ? <ExpandLess /> : <ExpandMore />}
        >
          {action === "add" ? "Add" : "Remove"}
        </Button>

        <Collapse
          className="multibar-collapsed collapsed-inside folder-multi-actions__remove-btn"
          in={openRemove}
          timeout="auto"
          unmountOnExit
        >
          <ClickAwayListener onClickAway={() => clickAwayMultibarRemove()}>
            <List component="div" disablePadding>
              {savedFolders?.map((folder, key) => {
                return (
                  <div
                    className="folder-container"
                    id={`folder-${action}-container-` + folder.folder_id}
                    key={key}
                  >
                    <ListItemButton
                      id={folder.folder_id}
                      sx={{ pl: 4 }}
                      className="folder-action-btn"
                      onClick={() => {
                        addItemToFolder(folder, action);
                        animateFolderAction(folder.folder_id, action);
                      }}
                    >
                      <ListItemIcon>{findIcon(folder)}</ListItemIcon>
                      <ListItemText primary={folder.name} />
                      <ListItemIcon sx={{ justifyContent: "flex-end" }}>
                        {action === "add" ? (
                          <AddCircleIcon color={"green_dark"} />
                        ) : (
                          <RemoveCircleIcon color={"red_dark"} />
                        )}
                      </ListItemIcon>
                    </ListItemButton>

                    <span className="folder-action-span">
                      {action === "add"
                        ? "Added to folder"
                        : "Removed from folder"}
                    </span>
                  </div>
                );
              })}
            </List>
          </ClickAwayListener>
        </Collapse>
      </div>
    </Tooltip>
  );
};

export default FoldersButton;
