import { Fade, LinearProgress, Stack } from "@mui/material";
import React from "react";
import { BsBoxSeam, BsShopWindow } from "react-icons/bs";
import { GiWineBottle } from "react-icons/gi";
import { IoIosLogIn } from "react-icons/io";
import { PiTruck } from "react-icons/pi";
import LanguageIcon from "@mui/icons-material/Language";

const CompanyType = ({ fetching, companyData, id }) => {
  return (
    <>
      {fetching ? (
        <div className={"company__section-type fade-appear"}>
          <div className="company__type-h company__heading">Company type</div>

          <div className="company__btm-type">
            {/* <StoreIcon /> */}
            {companyData.type?.split(",").length > 0
              ? companyData.type?.split(",")?.map((type, index) => {
                  return (
                    <span key={"type" + index + id} className="btm-types__item">
                      {type === "Retailer" ? (
                        <GiWineBottle size={20} />
                      ) : type === "Wholesaler" ? (
                        <BsBoxSeam size={16} />
                      ) : type === "Distributor" ? (
                        <PiTruck size={20} />
                      ) : type === "Supermarket" ? (
                        <BsShopWindow size={20} />
                      ) : type === "Importer" ? (
                        <IoIosLogIn size={20} />
                      ) : type === "E-Commerce" ? (
                        <LanguageIcon size={20} />
                      ) : null}
                      {type}
                    </span>
                  );
                })
              : null}
          </div>
        </div>
      ) : companyData["type"] !== "0" && companyData["type"] !== "" ? (
        <Fade
          unmountOnExit={true}
          in={true}
          timeout={{ enter: 400, exit: 200 }}
        >
          <span className="wide-loader-item wide-loader-item--full">
            <Stack sx={{ width: "100%", color: "grey.500" }} spacing={4}>
              <LinearProgress
                color="primary_grey_light_light"
                sx={{
                  height: "25px",
                  borderRadius: "2px",
                }}
              />
            </Stack>
          </span>
        </Fade>
      ) : null}
    </>
  );
};

export default CompanyType;
