import { Button, Fade, LinearProgress, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { handleSaveNote } from "../../../actions/content";

const CompanyNote = ({ fetching, companyData }) => {
  const [noteSaved, setNoteSaved] = useState(false);
  const [noteValue, setNoteValue] = useState("");

  const { id } = companyData;

  useEffect(() => {
    if (companyData) {
      setNoteValue(
        companyData?.notes
          ?.replaceAll("&quot;", "")
          .replaceAll("&amp;", "&")
          .replaceAll("&#039;", "'")
          .replaceAll("“", "")
          .replaceAll("”", "")
          .replaceAll('"', "")
      );
    }
  }, [companyData]);

  function recordNote(event) {
    setNoteValue(event.target.value);
  }
  function saveNote() {
    const params = {
      saved_note: noteValue,
      item_id: id,
    };

    handleSaveNote(params).then((data) => {
      setNoteSaved(data);
    });
    setTimeout(() => {
      setNoteSaved(false);
    }, "4000");
  }
  return (
    <>
      {fetching ? (
        <div className="distributor-notes fade-appear">
          <TextField
            id={"outlined-multiline-static-" + id}
            className="distributor-notes__input"
            label="Your private notes"
            multiline
            rows={6}
            onChange={recordNote}
            value={noteValue}
          />

          <span className="distributor-notes__h">
            You can edit this description
          </span>

          <div
            className={
              "distributor-notes__notification" +
              (noteSaved ? " distributor-notes__notification--visible" : "")
            }
          >
            Saved
          </div>

          <Button
            onClick={saveNote}
            size={"small"}
            color={"primary_grey_light"}
            variant="contained"
            className="distributor-notes__save-btn"
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </div>
      ) : (
        <Fade
          unmountOnExit={true}
          in={true}
          timeout={{ enter: 400, exit: 200 }}
        >
          <span className="wide-loader-item wide-loader-item--full--block">
            <Stack sx={{ width: "100%", color: "grey.500" }} spacing={4}>
              <LinearProgress
                color="primary_grey_light_light"
                sx={{
                  height: "25px",
                  borderRadius: "2px",
                }}
              />
            </Stack>
          </span>
        </Fade>
      )}
    </>
  );
};

export default CompanyNote;
