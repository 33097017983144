import React from "react";
import Fade from "@mui/material/Fade";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

// TODO i18n translates
// TODO FIX make sticky position

const EmailInfoModal = ({ opened, close }) => {
  return (
    <ClickAwayListener onClickAway={close}>
      <Fade in={opened} className="modal-window-content">
        <div>
          <div className="modal-window-content__close">
            <IconButton size="medium" aria-label="delete" onClick={close}>
              <CloseIcon fontSize="medium" />
            </IconButton>
          </div>

          <div>
            <h3>Email Deliverability Status Explanation</h3>

            <div className="modal-window-content__content">
              <ul className="modal-window-content__list">
                <li>
                  <span className="modal-window-content__status modal-window-content__status--green">
                    <MarkEmailReadIcon />
                    <span>Verified</span>
                  </span>

                  <span className="modal-window-content__desc">
                    Email confirmed and working correctly
                  </span>
                </li>

                <li>
                  <span className="modal-window-content__status modal-window-content__status--green">
                    <MarkEmailReadIcon />
                    <span>Verified | Role-based</span>
                  </span>

                  <span className="modal-window-content__desc">
                    This email address is confirmed and working. ‘Role-based’
                    means that this email belongs to a specific work group
                    (sales@, info@). Role-based emails has a higher percentage
                    of send to spam than ‘Verified’ emails.
                  </span>
                </li>

                <li>
                  <span className="modal-window-content__status modal-window-content__status--yellow">
                    <MarkEmailReadIcon />
                    <span>Verified | Catch-all</span>
                  </span>

                  <span className="modal-window-content__desc">
                    ‘Catch-all’ means that, although the mail server is active,
                    our checks do not show its precise status. Some emails sent
                    to this server will bounce back.
                  </span>
                </li>

                <li>
                  <span className="modal-window-content__status modal-window-content__status--yellow">
                    <MarkEmailReadIcon />
                    <span>Verified | Abuse</span>
                  </span>

                  <span className="modal-window-content__desc">
                    The email address exists and is verified. Abuse means, these
                    emails are of people known to click the abuse links in
                    emails, hence abusers or complainers.
                  </span>
                </li>

                <li>
                  <span className="modal-window-content__status modal-window-content__status--yellow">
                    <MarkEmailReadIcon />
                    <span>Verified | Antispam</span>
                  </span>

                  <span className="modal-window-content__desc">
                    We have confirmed that this email address exists and works,
                    but an anti-spam system has been detected on the mail
                    server, and your email may bounce back.
                  </span>
                </li>
                <li>
                  <span className="modal-window-content__status modal-window-content__status--yellow">
                    <MarkEmailReadIcon />
                    <span>Verified | Redirect?</span>
                  </span>

                  <span className="modal-window-content__desc">
                    The email address has been verified and exists; however, a
                    redirection from this address has also been detected. It is
                    possible that this is a temporary email or that messages are
                    being forwarded to another address.
                  </span>
                </li>
                <li>
                  <span className="modal-window-content__status modal-window-content__status--grey">
                    <HourglassTopIcon />
                    <span>Unknown</span>
                  </span>

                  <span className="modal-window-content__desc">
                    We couldn’t validate this email. Typical cases include an
                    offline mail server or an anti-spam email system. In most
                    cases, unknown emails are invalid/bad email addresses.
                  </span>
                </li>

                <li>
                  <span className="modal-window-content__status modal-window-content__status--red">
                    <DoNotDisturbIcon />
                    <span>Verified | Do not use</span>
                  </span>

                  <span className="modal-window-content__desc">
                    Email owners are complainers, direct complainers, purchased
                    addresses, domains that don't send mail, and known
                    litigators.
                  </span>
                </li>

                <li>
                  <span className="modal-window-content__status modal-window-content__status--red">
                    <DoNotDisturbIcon />
                    <span>Inactive email</span>
                  </span>

                  <span className="modal-window-content__desc">
                    This means that either the email is currently inactive or
                    the email contained an error. We are working to replace
                    these email addresses.
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Fade>
    </ClickAwayListener>
  );
};

export default EmailInfoModal;
