import React from "react";
import { IconButton } from "@mui/material";
import logoPng from "./../../assets/vinaty-logo-135.png";
import CloseIcon from "@mui/icons-material/Close";
import { toggleSidebar } from "../../utils";

const SidebarHeader = () => {
  return (
    <>
      <div className="close-sidebar" onClick={toggleSidebar}>
        <IconButton size={"large"} aria-label="delete" color="primary">
          <CloseIcon />
        </IconButton>
      </div>

      <div className="sidebar__top-container">
        <div className="sidebar__vinaty">
          <img src={logoPng} alt="Vinaty Logo" />
        </div>
      </div>
    </>
  );
};

export default SidebarHeader;
