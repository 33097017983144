import React, { useState } from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { GiWineBottle } from "react-icons/gi";
import { BsBoxSeam, BsShopWindow } from "react-icons/bs";
import { PiTruck } from "react-icons/pi";
import HelpIcon from "@mui/icons-material/Help";
// TODO i18n
const TableHeader = () => {
  const [openTypeInfo, setOpenTypeInfo] = useState("");

  function openTypeInfoHandler(inp) {
    if (inp !== openTypeInfo) {
      setOpenTypeInfo(inp);
    } else {
      setOpenTypeInfo("");
    }
  }
  function CloseTypeInfo() {
    setOpenTypeInfo("");
  }

  return (
    <div className="results__headings">
      <div className="results__container-headings">
        <div className="headings__checkbox"></div>

        <div className="headings__name">Name</div>

        <div className="headings__action-signs">
          Status
          <HelpIcon
            className="question-toggle"
            sx={{ fontSize: 20 }}
            onClick={() => openTypeInfoHandler("status")}
          />
          <Collapse
            className="question-info question-info--status"
            in={openTypeInfo === "status"}
            timeout="auto"
            unmountOnExit
          >
            <IconButton
              className="question-info__close"
              aria-label="delete"
              onClick={CloseTypeInfo}
            >
              <CloseIcon />
            </IconButton>

            <p className="question-info__heading">
              <span className="class class-c">
                <span className="class__letter">C</span>
                Tier
              </span>
            </p>
            <p className="question-info__text">
              Company information including the trade name, website, social
              media, general email, phone number, address, company type, product
              types, and the countries of origin of the wine.
            </p>

            <p className="question-info__heading">
              <span className="class class-b">
                <span className="class__letter">B</span>
                Tier
              </span>
            </p>
            <p className="question-info__text">
              The company's data has been updated relatively recently and
              includes additional details (LinkedIn, number of employees, more
              useful links, and contacts), providing more accurate and
              comprehensive information.
            </p>

            <p className="question-info__heading">
              <span className="class class-a">
                <span className="class__letter">A</span>
                Tier
              </span>
            </p>
            <p className="question-info__text">
              If available for the company, this includes legal company data,
              company profit or sales, names of key employees and their contact
              details (positions, emails, phone numbers, LinkedIn links), the
              number of wines in the company's portfolio, and the year of
              establishment.
            </p>
          </Collapse>
        </div>

        <div className="headings__region-country">Location</div>

        <div className="headings__type">
          <span className="type__text">Type</span>

          <HelpIcon
            className="question-toggle"
            sx={{ fontSize: 20 }}
            onClick={() => openTypeInfoHandler("type")}
          />

          <Collapse
            className="question-info question-info--types"
            in={openTypeInfo === "type"}
            timeout="auto"
            unmountOnExit
          >
            <IconButton
              className="question-info__close"
              aria-label="delete"
              onClick={CloseTypeInfo}
            >
              <CloseIcon />
            </IconButton>

            <p className="question-info__heading">
              <GiWineBottle size={24} />
              Retailer
            </p>
            <p className="question-info__text">
              Retailers are businesses that sell products directly to consumers.
              These can be restaurants, bars, wine stores. Companies can often
              be both a retailer and a wholesaler.
            </p>

            <p className="question-info__heading">
              <BsBoxSeam size={20} />
              Wholesaler
            </p>
            <p className="question-info__text">
              Wine wholesalers buy a large quantity of wines and products
              directly from distributors. The wines are destined for retailers.
            </p>

            <p className="question-info__heading">
              <PiTruck size={24} />
              Distributor and Importers
            </p>
            <p className="question-info__text">
              Wine distributors/importers have a business relationship with the
              wineries that they represent. Importers bring wines into a country
              from abroad. Distributors usually cover a certain territory or
              region.
            </p>
            <p className="question-info__heading">
              <BsShopWindow size={24} />
              Supermarket Chain
            </p>
            <p className="question-info__text">
              Supermarkets are large retail chains, that share a brand and
              central management. At their core, they are retailers and have
              their own large distribution network.
            </p>
          </Collapse>
        </div>

        <div className="headings__products">Products</div>

        <div className="headings__size">Size</div>
        <div className="headings__emails">
          Contacts
          <HelpIcon
            className="question-toggle"
            sx={{ fontSize: 20 }}
            onClick={() => openTypeInfoHandler("contacts")}
          />
          <Collapse
            className="question-info question-info--contacts"
            in={openTypeInfo === "contacts"}
            timeout="auto"
            unmountOnExit
          >
            <IconButton
              className="question-info__close"
              aria-label="delete"
              onClick={CloseTypeInfo}
            >
              <CloseIcon />
            </IconButton>

            <p className="question-info__heading">Contact Counters</p>
            <p className="question-info__text">
              The number of contacts available for this company. From left to
              right: the number of verified emails, phone numbers, links to
              LinkedIn profiles (general and key employees), links (Facebook,
              LinkedIn, Instagram, Twitter, company website).
            </p>
          </Collapse>
        </div>

        <div className="headings__phones">{/* Phones */}</div>

        <div className="headings__linkedin">{/* Linkedin */}</div>

        <div className="headings__social">{/* Links */}</div>
        <div className="headings__opener">Open</div>
      </div>
    </div>
  );
};

export default TableHeader;
