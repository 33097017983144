import React from "react";
import { SavesProvider } from "./SavesContext";
import { FoldersProvider } from "./FoldersContext";
import { UserProvider } from "./UserContext";
import { ContentProvider } from "./ContentContext";
import { ThemeProvider } from "@mui/material";
import { theme } from "../constants/theme";
import { CurrencyProvider } from "./CurrencyContext";

const CustomProviders = ({ children }) => {
  return (
    <div translate="no">
      <UserProvider>
        <ContentProvider>
            <FoldersProvider>
              <SavesProvider>
                <CurrencyProvider>
                  <ThemeProvider theme={theme}>{children}</ThemeProvider>
                </CurrencyProvider>
              </SavesProvider>
            </FoldersProvider>
        </ContentProvider>
      </UserProvider>
    </div>
  );
};

export default CustomProviders;
