import { useEffect } from "react";
import { useSaves } from "./SavesContext";
import { useFolders } from "./FoldersContext";
import { handleFetchUser } from "../actions/user";
import { useUser } from "./UserContext";

// Refactored

const CheckUser = (props) => {
  const { setUserDetails, setCheat } = useUser();
  const { setSavedSearches } = useSaves();
  const { setSavedFolders } = useFolders();
  function decryptToken() {
    handleFetchUser().then((data) => {
      setUserDetails(data["user_details"]);
      setSavedSearches(data["user_saves"]);
      setSavedFolders(data["user_folders"]);
      setCheat(data["cheat"]);
    });
  }

  useEffect(() => {
    decryptToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default CheckUser;
