import { Button } from "@mui/material";
import React from "react";

const SidebarButton = ({ title, subTitle, onClick, additionalClass, startIconIns,endIconIns = null}) => {
  return (
    <Button
      size={"large"}
      sx={{ fontSize: "18px", fontWeight: "bold", textTransform: "none" }}
      className={`sidebar__button ${additionalClass}`}
      color="primary"
      startIcon={startIconIns}
      endIcon={endIconIns}
      onClick={onClick}
    >
      {title}
      {subTitle.length > 0 && (
        <span className="counter-button">{subTitle}</span>
      )}
    </Button>
  );
};

export default SidebarButton;
