import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

const FileFormatsCheckbox = ({ label, checked, onChange, name }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          name={name}
          color="primary_grey_light"
        />
      }
      label={label}
    />
  );
};

export default FileFormatsCheckbox;
