import { Button, Fade, LinearProgress, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import FacebookIcon from "@mui/icons-material/Facebook";
import { FaSquareXTwitter } from "react-icons/fa6";
import InstagramIcon from "@mui/icons-material/Instagram";
import { FaFacebookSquare } from "react-icons/fa";

const CompanyLinks = ({ fetching, companyData }) => {
  const [facebookLink, setFacebookLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [xLink, setXLink] = useState("");
  useEffect(() => {
    if (companyData) {
      var socialLinks = companyData?.facebook?.split(",");
      var tempFacebook = "unknown";
      var tempInstagram = "unknown";
      var tempX = "unknown";
      for (let i = 0; i < socialLinks?.length; i++) {
        if (socialLinks[i].includes("facebook")) {
          tempFacebook =
            "https://facebook" + socialLinks[i].split("facebook")[1];
        }
        if (socialLinks[i].includes("instagram")) {
          tempInstagram =
            "https://instagram" + socialLinks[i].split("instagram")[1];
        }
        if (
          socialLinks[i].includes("www.x.") ||
          socialLinks[i].includes("/x.") ||
          socialLinks[i].includes("twitter")
        ) {
          tempX = socialLinks[i];
        }
      }
      setFacebookLink(tempFacebook);
      setInstagramLink(tempInstagram);
      setXLink(tempX);
    }
  }, [companyData]);

  return (
    <>
      {fetching ? (
        <div className={"company__section company__section-links fade-appear"}>
          <div className="company__links-h company__heading">
            Links and social
          </div>

          <div className="company__links-content company__text-content">
            {companyData?.website?.includes(".") ? (
              <Button
                href={companyData.website}
                target="_blank"
                className="button-social button-social--website"
                color="blue_button_link"
                size="small"
                variant="contained"
                startIcon={<InsertLinkIcon />}
                sx={{
                  borderRadius: "2px",
                  textTransform: "none",
                }}
              >
                Website
              </Button>
            ) : null}

            {companyData?.contact?.includes(".") ? (
              <Button
                href={companyData.contact}
                target="_blank"
                className="button-social button-social--website"
                color="blue_button_link"
                size="small"
                variant="contained"
                startIcon={<InsertLinkIcon />}
                sx={{
                  borderRadius: "2px",
                  textTransform: "none",
                }}
              >
                Contact
              </Button>
            ) : null}

            {facebookLink !== "unknown" ? (
              <Button
                className="button-social button-social--facebook"
                // color="secondary_light"
                color="blue_button_link"
                size="small"
                href={facebookLink}
                target="_blank"
                variant="contained"
                startIcon={<FacebookIcon />}
                sx={{
                  borderRadius: "2px",
                  textTransform: "none",
                }}
              >
                Facebook
              </Button>
            ) : null}

            {instagramLink !== "unknown" ? (
              <Button
                className="button-social button-social--instagram"
                // color="insta_light"
                color="blue_button_link"
                size="small"
                href={instagramLink}
                target="_blank"
                variant="contained"
                startIcon={<InstagramIcon />}
                sx={{
                  borderRadius: "2px",
                  textTransform: "none",
                }}
              >
                Instagram
              </Button>
            ) : null}

            {xLink !== "unknown" ? (
              <Button
                className="button-social button-social--x"
                // color="x_light"
                color="blue_button_link"
                size="small"
                href={xLink}
                target="_blank"
                variant="contained"
                startIcon={<FaSquareXTwitter />}
                sx={{
                  borderRadius: "2px",
                  textTransform: "none",
                }}
              >
                X
              </Button>
            ) : xLink === "unknown" &&
              instagramLink === "unknown" &&
              facebookLink === "unknown" ? (
              <span className="single-bottom-info__no-info no-info--social">
                <FaFacebookSquare />
                No social media accounts found
              </span>
            ) : null}
          </div>
        </div>
      ) : (
        <Fade
          unmountOnExit={true}
          in={true}
          timeout={{ enter: 400, exit: 200 }}
        >
          <span className="wide-loader-item wide-loader-item--full">
            <Stack sx={{ width: "100%", color: "grey.500" }} spacing={4}>
              <LinearProgress
                color="primary_grey_light_light"
                sx={{
                  height: "25px",
                  borderRadius: "2px",
                }}
              />
            </Stack>
          </span>
        </Fade>
      )}
    </>
  );
};

export default CompanyLinks;
