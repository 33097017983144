import React from "react";
import { CopyIcon } from "../../CopyIcon";
import { Fade, LinearProgress, Stack } from "@mui/material";
import TagIcon from "@mui/icons-material/Tag";

const CompanyVat = ({fetching, companyData}) => {
  return (
    <>
      {fetching ? (
        <div className={"company__section-id fade-appear"}>
          {companyData["national_id"] === "0" ? null : (
            <div className="company__id">
              <div className="company__id-h company__heading">National ID</div>

              <div className="company__id-content company__text-content">
                <TagIcon />
                {companyData["national_id"]}
                <CopyIcon left value={companyData.national_id} />
              </div>
            </div>
          )}

          {companyData["vat"] === "0" ? null : (
            <div className="company__vat">
              <div className="company__vat-h company__heading">VAT</div>
              <div className="company__vat-content company__text-content">
                <TagIcon />
                {companyData.vat}
                <CopyIcon left value={companyData.vat} />
              </div>
            </div>
          )}

          {companyData["add_id"] === "0" ||
          (companyData["add_id"] !== "0" &&
            (companyData["national_id"] !== "0" ||
              companyData["vat"] !== "0")) ? null : (
            <div className="company__vat">
              <div className="company__vat-h company__heading">
                {companyData["country_code"] === "BE"
                  ? "KBO"
                  : companyData["country_code"] === "DE"
                  ? "German Trade Register"
                  : companyData["country_code"] === "US"
                  ? "Licence"
                  : companyData["country_code"] === "LI"
                  ? "ID (additional)"
                  : companyData["country_code"] === "PH"
                  ? "License"
                  : ""}
              </div>
              <div className="company__vat-content company__text-content">
                <TagIcon />
                {companyData["add_id"]}
                <CopyIcon left value={companyData.add_id} />
              </div>
            </div>
          )}
        </div>
      ) : companyData["national_id"] !== "0" || companyData["vat"] !== "0" ? (
        <Fade
          unmountOnExit={true}
          in={true}
          timeout={{ enter: 400, exit: 200 }}
        >
          <span className="wide-loader-item wide-loader-item--full">
            <Stack sx={{ width: "100%", color: "grey.500" }} spacing={4}>
              <LinearProgress
                color="primary_grey_light_light"
                sx={{
                  height: "25px",
                  borderRadius: "2px",
                }}
              />
            </Stack>
          </span>
        </Fade>
      ) : null}
    </>
  );
};

export default CompanyVat;
