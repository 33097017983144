import React, { createContext, useState, useContext } from "react";

const SavesContext = createContext();

export const SavesProvider = ({ children }) => {
  const [savedSearches, setSavedSearches] = useState([]);
  const [savesOpened, setSavesOpened] = useState(false);
  return (
    <SavesContext.Provider value={{ savedSearches, setSavedSearches, savesOpened, setSavesOpened }}>
      {children}
    </SavesContext.Provider>
  );
};

export const useSaves = () => {
  return useContext(SavesContext);
};
