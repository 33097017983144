import { Button, Fade, LinearProgress, Stack } from "@mui/material";
import React from "react";
import { createLinkedinLink } from "../../../utils";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const CompanyLinkedIn = ({ fetching, companyData }) => {
  return (
    <>
      {fetching &&
      companyData?.linkedin !== "" &&
      companyData?.linkedin !== "0" ? (
        <div
          className={"company__section company__section-linkedin fade-appear"}
        >
          <div className="company__linkedin-h company__heading">Linkedin</div>

          <div className="company__linkedin-content company__text-content">
            {companyData?.linkedin?.split(",").map((linkedin, index) => {
              return (
                <Button
                  key={"linkd" + index}
                  href={createLinkedinLink(linkedin)}
                  target="_blank"
                  className="linkedin-item button-custom-shadow"
                  color="blue_button_link"
                  size="small"
                  variant="contained"
                  startIcon={<LinkedInIcon />}
                >
                  {linkedin.split(":")[0]?.includes("http") ||
                  linkedin.split(":")[0]?.includes("linkedin")
                    ? "Company"
                    : linkedin.split(":")[0]}
                </Button>
              );
            })}
          </div>
        </div>
      ) : fetching &&
        companyData?.linkedin !== "" &&
        companyData?.linkedin !== "0" ? (
        <Fade
          unmountOnExit={true}
          in={true}
          timeout={{ enter: 400, exit: 200 }}
        >
          <span className="wide-loader-item wide-loader-item--full">
            <Stack sx={{ width: "100%", color: "grey.500" }} spacing={4}>
              <LinearProgress
                color="primary_grey_light_light"
                sx={{
                  height: "25px",
                  borderRadius: "2px",
                }}
              />
            </Stack>
          </span>
        </Fade>
      ) : null}
    </>
  );
};

export default CompanyLinkedIn;
