import React, { useState } from "react";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";

import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { findIcon } from "../../utils";
import { useFolders } from "../../hooks/FoldersContext";
import { handleAddItemToFolder } from "../../actions/folders";

export default function FoldersAction(props) {
  const { item, typeActionButton } = props;

  const [open, setOpen] = useState(false);
  const { savedFolders, setSavedFolders } = useFolders();

  const handleClick = (event) => {
    event.stopPropagation();
    setOpen(!open);
  };

  // Action button add item to the folder
  function addItemToFolder(folder, action) {
    const params = {
      action: action,
      item_id: [item.id],
      folder_id: folder.folder_id,
    };

    handleAddItemToFolder(params)
      .then((data) => {
        setSavedFolders(data);
      })
      .catch((error) => {
        window.location.replace("https://vinaty.com/login/");
        alert(
          "Something went wrong during save of the content. Error code p003"
        );
      });
  }

  // Check if that ITEM is in the folder or not
  function removeOrAdd(savedFolders, folder) {
    for (let i = 0; i < savedFolders?.length; i++) {
      if (savedFolders[i]["folder_id"] === folder["folder_id"]) {
        var thisFolderArr = savedFolders[i]["content"].split(",");
        thisFolderArr.includes(item.id);
        if (thisFolderArr.includes(item.id)) {
          // If ITEM was added before to this FOLDER
          return (
            <ListItemButton
              // key={key}
              id={folder.folder_id}
              sx={{ pl: 4 }}
              className="folder-action-btn"
              onClick={() => addItemToFolder(folder, "remove")}
            >
              <ListItemIcon>{findIcon(folder)}</ListItemIcon>

              <ListItemText primary={folder.name} />

              <ListItemIcon sx={{ justifyContent: "flex-end" }}>
                <RemoveCircleIcon color={"red_dark"} />
              </ListItemIcon>
            </ListItemButton>
          );
        } else {
          return (
            <ListItemButton
              // key={key}
              id={folder.folder_id}
              sx={{ pl: 4 }}
              className="folder-action-btn"
              onClick={() => addItemToFolder(folder, "add")}
            >
              <ListItemIcon>{findIcon(folder)}</ListItemIcon>

              <ListItemText primary={folder.name} />

              <ListItemIcon sx={{ justifyContent: "flex-end" }}>
                <AddCircleIcon color={"green_dark"} />
              </ListItemIcon>
            </ListItemButton>
          );
        }
      }
    }
  }

  function closeFolderList() {
    if (open) {
      setOpen(false);
    }
  }

  return (
    <div
      id={"single-action-" + item.id}
      className={
        typeActionButton !== 2 && typeActionButton !== "2"
          ? "pagination__actions"
          : "distributor__actions"
      }
    >
      {/* <Collapse in={expanded === item.id} timeout="auto" unmountOnExit> */}
      <ListItemButton
        className={
          "distributor__folder-action" +
          (savedFolders.length < 1
            ? " distributor__folder-action--inactive"
            : "")
        }
        onClick={savedFolders.length > 0 ? (event) => handleClick(event) : null}
      >
        <ListItemIcon>
          <DriveFileMoveIcon color={"primary_grey_light"} />
        </ListItemIcon>

        <ListItemText primary="Folders Management" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <ClickAwayListener onClickAway={closeFolderList}>
        <Collapse
          className="collapsed-inside"
          in={open}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {savedFolders?.map((folder, key) => {
              return <div key={key}>{removeOrAdd(savedFolders, folder)}</div>;
            })}
          </List>
        </Collapse>
      </ClickAwayListener>
      {/* </Collapse> */}
    </div>
  );
}
