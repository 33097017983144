import React, { useEffect, useState } from "react";
// Components
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import CircleIcon from "@mui/icons-material/Circle";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";

// Constants
import { foldersIconsArray as iconsArray } from "../../constants";
import { foldersColorsArray as colorsArray } from "../../constants";

// Hooks and actions
import { useFolders } from "../../hooks/FoldersContext";
import { handleCreateFolder, handleDeleteFolder } from "../../actions/folders";

const EditFolder = ({ opened, toggleVisibility, folderId }) => {
  const { savedFolders, setSavedFolders } = useFolders();

  const [folderName, setFolderName] = useState("New Folder");
  const [chosenColor, setChosenColor] = useState("#c683d2");
  const [chosenIcon, setChosenIcon] = useState("FolderIcon");

  useEffect(() => {
    if (folderId !== 0) {
      savedFolders.map((folder) => {
        if (folder.folder_id === folderId) {
          setFolderName(folder.name);
          setChosenColor(folder.color);
          setChosenIcon(folder.icon);
          return null;
        } else {
          return null;
        }
      });
    } else {
      setFolderName("New Folder");
      setChosenColor("#c683d2");
      setChosenIcon("FolderIcon");
    }
  }, [folderId, savedFolders]);

  // Function to create Folder
  async function createFolder() {
    toggleVisibility();
    const props = {
      folder_name: folderName,
      folder_icon: chosenIcon,
      folder_color: chosenColor,
      folder_content: "",
      folder_id: folderId,
    };

    const data = await handleCreateFolder(props);
    setSavedFolders(data);
  }

  async function deleteFolder() {
    toggleVisibility();
    const params = {
      folder_id: folderId,
    };
    const data = await handleDeleteFolder(params);
    setSavedFolders(data);
  }

  return (
    <div
      className={
        "sidebar-absolute-slide slide__folder" +
        (!opened ? " sidebar-absolute-slide--active" : "")
      }
    >
      <div className="sidebar-absolute">
        <div className="folder__heading-container">
          <p className="folder__heading">
            {folderId === 0 || folderId === "0"
              ? "Create new folder"
              : "Edit folder"}
          </p>

          <IconButton
            onClick={toggleVisibility}
            aria-label="close"
            size="medium"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>

        {/* NAME FOLDER */}

        <div className="folder__naming-container">
          <TextField
            label="Folder name"
            id="outlined-size-small"
            // defaultValue="New folder"
            size="small"
            className="folder-name-text-field"
            color={"primary"}
            value={folderName}
            onChange={(event) => setFolderName(event.target.value)}
          />
        </div>

        {/* COLOR PICKER */}

        <div className="folder__color-container">
          <div className="folder__color-picker">
            <p>Choose color</p>
            <CircleIcon sx={{ color: chosenColor }} />
          </div>

          {colorsArray.map((color, index) => {
            return (
              <IconButton
                key={index}
                onClick={() => setChosenColor(color)}
                size={"medium"}
                aria-label="choose color"
                className={chosenColor === color ? "color--active" : ""}
              >
                <CircleIcon
                  size={"medium"}
                  sx={{ color: color, fontSize: "28px" }}
                />
              </IconButton>
            );
          })}
        </div>

        {/* ICON PICKER */}

        <div className="folder__icon-container">
          <div className="folder__icon-picker">
            <p>Choose icon</p>
          </div>

          {iconsArray.map((icon, index) => {
            return (
              <IconButton
                key={index}
                onClick={() => setChosenIcon(icon.name)}
                size={"medium"}
                aria-label="choose icon"
                className={chosenIcon === icon.name ? "icon--active" : ""}
              >
                <icon.icon
                  size={"medium"}
                  sx={{ color: chosenColor, fontSize: "28px" }}
                />
              </IconButton>
            );
          })}
        </div>

        <div className="folder__button-container">
          {folderId !== 0 ? (
            <Button
              className="folder__button-delete"
              size={"small"}
              sx={{ marginTop: "15px", marginBottom: "15px" }}
              color="red_dark"
              startIcon={<DeleteIcon />}
              onClick={deleteFolder}
            >
              Delete
            </Button>
          ) : null}

          <Button
            size={"small"}
            sx={{
              textTransform: "none",
              marginTop: "15px",
              marginBottom: "15px",
            }}
            color="primary_grey_light"
            startIcon={<CreateNewFolderIcon />}
            onClick={createFolder}
            variant="contained"
          >
            Save Folder
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditFolder;
