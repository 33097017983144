import { Button, Fade, LinearProgress, Stack } from "@mui/material";
import React, { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import CountryCodes from "../../../assets/country_codes.json";
import WineBarIcon from "@mui/icons-material/WineBar";

const CompanyCountries = ({ fetching, companyData }) => {
  const [showFlags, setShowFlags] = useState(false);
  function showFlagsFunc() {
    setShowFlags((showFlags) => !showFlags);
  }
  return (
    <>
      {fetching ? (
        <div className={"company__section-prodcountry fade-appear"}>
          <div className="company__prodcountry-h company__heading">
            Distributing from
          </div>

          <div className="company__btm-prod-country">
            <WineBarIcon />
            {companyData["prod_country"]?.length > 0 &&
            companyData["prod_country"] !== "all" ? (
              <>
                {companyData["prod_country"]
                  .split(",")
                  .map((country, index) => {
                    if ((!showFlags && index < 12) || showFlags) {
                      const countryEntry = Object.entries(CountryCodes).find(
                        ([code]) => code.toLowerCase() === country
                      );

                      if (countryEntry) {
                        const [tempCode, countryName] = countryEntry;
                        return (
                          <div
                            className="company__flag-country"
                            key={`flag${tempCode}${index}`}
                          >
                            <ReactCountryFlag
                              countryCode={tempCode.toLowerCase()}
                              svg
                              style={{
                                width: "1.1em",
                                height: "1.1em",
                              }}
                            />
                            {countryName}
                          </div>
                        );
                      } else if (country === "global" || country === "other") {
                        return (
                          <span key={`global${index}`} className="global-flag">
                            and others
                          </span>
                        );
                      }
                    }
                    return null;
                  })}
                {companyData["prod_country"].split(",").length > 13 && (
                  <Button
                    className="sm-in-open"
                    color="primary_grey_light"
                    size="small"
                    variant="outlined"
                    onClick={showFlagsFunc}
                    sx={{
                      borderRadius: "1px",
                      paddingLeft: "3px",
                      paddingRight: "3px",
                      height: "16px",
                      marginLeft: "4px",
                      textTransform: "none",
                      fontSize: "0.72rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {!showFlags ? "Show More" : "Hide"}
                  </Button>
                )}
              </>
            ) : (
              "Distribution of wines without specialization by country"
            )}
          </div>
        </div>
      ) : companyData["prod_country"] !== "0" ? (
        <Fade
          unmountOnExit={true}
          in={true}
          timeout={{ enter: 400, exit: 200 }}
        >
          <span className="wide-loader-item wide-loader-item--full">
            <Stack sx={{ width: "100%", color: "grey.500" }} spacing={4}>
              <LinearProgress
                color="primary_grey_light_light"
                sx={{
                  height: "25px",
                  borderRadius: "2px",
                }}
              />
            </Stack>
          </span>
        </Fade>
      ) : null}
    </>
  );
};

export default CompanyCountries;
