import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ruTranslation from "../locales/ru.json";
import enTranslation from "../locales/en.json";
import itTranslation from "../locales/it.json";
const getBrowserLanguage = () => {
  const language = navigator.language || navigator.userLanguage;
  return language.split("-")[0];
};

const browserLanguage = getBrowserLanguage();
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: browserLanguage,
    supportedLngs: ["de"],
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      ru: {
        translation: ruTranslation,
      },
      en: {
        translation: enTranslation,
      },
      it: {
        translation: itTranslation,
      },
    },
    detection: {
      order: ["queryString", "cookie"],
      cache: ["cookie"],
    },
  });

export default i18n;
