import React, { useEffect, useState } from "react";

// Material UI
import {
  Button,
  TextField,
  InputAdornment,
  Fade,
  IconButton,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import TurnedInSharpIcon from "@mui/icons-material/TurnedInSharp";
import CloseIcon from "@mui/icons-material/Close";
import { handleSaveSearch } from "../../actions/searches";
import { useSaves } from "../../hooks/SavesContext";
import { useContent } from "../../hooks/ContentContext";

const PaginationSave = () => {
  const [saved, setSaved] = useState(false);
  const [opened, setOpened] = useState(false);
  const { setSavedSearches } = useSaves();
  const [saveName, setSaveName] = useState("");
  const { fetching, requestParams } = useContent();

  useEffect(() => {
    if (!opened) {
      setSaveName("");
    }
  }, [opened]);

  const toggleOpen = () => {
    setOpened(!opened);
  };

  async function saveSearch() {
    setSaved(true);
    toggleOpen();
    const params = {
      request_params: JSON.stringify(requestParams),
      save_name: saveName,
    };
    const data = await handleSaveSearch(params);
    setSavedSearches(data);

    setTimeout(() => {
      setSaved(false);
    }, 5000);
  }

  const ImputEndAdornment = () => {
    return (
      <InputAdornment sx={{ width: "40%" }} position="end">
        <Button
          className="content-menu__input-button"
          size={"small"}
          variant="contained"
          color="primary_grey_light"
          onClick={!saved ? saveSearch : null}
          endIcon={<LabelImportantIcon />}
          sx={{ width: "100%" }}
        >
          Save
        </Button>
        <IconButton
          className="content-menu__close"
          size="small"
          aria-label="close"
          onClick={toggleOpen}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </InputAdornment>
    );
  };

  return (
    <>
      <Fade
        className="content-menu__save-box content-menu-collapsed-save"
        in={opened}
        unmountOnExit={true}
      >
        <TextField
          className="content-menu__input-save"
          onChange={(event) => {
            setSaveName(event.target.value);
          }}
          id="input-with-sx"
          label="Name of save"
          variant="outlined"
          color="primary_light"
          InputProps={{
            endAdornment: <ImputEndAdornment />,
          }}
        />
      </Fade>

      <Button
        disabled={fetching}
        size={"small"}
        className="content-menu__button"
        sx={{ marginLeft: "auto" }}
        variant="contained"
        startIcon={!saved ? <TurnedInSharpIcon /> : <DoneIcon />}
        onClick={!saved ? toggleOpen : null}
        type="button"
        aria-label="Name the save"
        color="primary_grey_light"
      >
        {!saved ? "Save search" : "Saved"}
      </Button>
    </>
  );
};

export default PaginationSave;
