import { getToken } from "../utils";
import Api from "../utils/api";

export async function handleSaveSearch(props) {
  try {
    const response = await Api.post("folders", {
      data: "save",
      token: getToken(),
      ...props,
    });
    return response.data;
  } catch (error) {
    alert("Something went wrong during save of the content. Error code 003");
    window.location.replace("https://vinaty.com/login/");
    console.error(error);
  }
}

export async function handleDeleteSave(save_id) {
  try {
    const response = await Api.post("folders", {
      data: "delete_save",
      token: getToken(),
      save_id,
    });
    return response.data;
  } catch (error) {
    alert("Something went wrong during save of the content. Error code 004");
    window.location.replace("https://vinaty.com/login/");
    console.error(error);
  }
}
