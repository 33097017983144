import {
  Fade,
  FormControl,
  IconButton,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import CalendarMonthSharpIcon from "@mui/icons-material/CalendarMonthSharp";
import PaymentsSharpIcon from "@mui/icons-material/PaymentsSharp";
import DataSaverOffIcon from "@mui/icons-material/DataSaverOff";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { currenciesArray } from "../../../constants";
import { useCurrency } from "../../../hooks/CurrencyContext";

const CompanySales = ({ fetching, companyData }) => {
  const [selectedCurrency, setSelectedCurrency] = useState("EUR");
  // eslint-disable-next-line no-unused-vars
  const { currencies } = useCurrency();

  const handleChangeCurrency = (event) => {
    setSelectedCurrency(event.target.value);
  };

  const getCurrencyInfo = (code) => {
    const currency = currenciesArray.find((item) => item.code === code);
    return currency ? currency.name : null;
  };

  // Функция для конвертации валют
  const convertCurrency = (amount, fromCurrency, toCurrency) => {
    if (!currencies[fromCurrency] || !currencies[toCurrency]) {
      //   throw new Error("Invalid currency code");
    }
    const rateFrom = currencies[fromCurrency];
    const rateTo = currencies[toCurrency];
    return (amount / rateFrom) * rateTo;
  };

  const convertAndDisplaySales = (sales, fromCountryCode, toCurrency) => {
    const salesAmount = parseFloat(
      sales.replace(/[^0-9.,-]/g, "").replace(",", ".")
    );
    const fromCurrency = getCurrencyInfo(fromCountryCode);

    if (fromCurrency && toCurrency) {
      const convertedAmount = convertCurrency(
        salesAmount,
        fromCurrency,
        toCurrency
      );
      return `${convertedAmount
        .toFixed(0)
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")}`;
    } else {
      return "Error: Currency code not found";
    }
  };

  return (
    <>
      {fetching &&
      ((companyData["sales"] !== "0" && companyData["sales"] !== "") ||
        (companyData["profit"] !== "0" && companyData["profit"] !== "") ||
        (companyData["add_finance"] !== "0" &&
          companyData["add_finance"] !== "")) ? (
        <div className={"company__section-finance fade-appear"}>
          {/* {(companyData['sales'] === '0' && companyData['profit'] === '0') || (companyData['fin_year'] === '0' || companyData['fin_year'] === '') ? null :  */}
          {companyData["sales"] === "0" &&
          companyData["profit"] === "0" &&
          companyData["add_finance"] === "0" ? null : (
            <div className="company__fin-year">
              <div className="company__fin-year-h company__heading">
                Currency:
              </div>

              <FormControl sx={{ width: 100 }}>
                <Select
                  id="currency-select"
                  value={selectedCurrency}
                  onChange={handleChangeCurrency}
                  sx={{
                    height: "2rem",
                    fontSize: "0.75rem",
                    ".MuiSelect-select": {
                      padding: "0.5rem",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        "& .MuiMenuItem-root": {
                          fontSize: "0.84rem", // Размер шрифта для элементов меню
                          padding: "0.5rem 1rem", // Отступы для элементов меню
                        },
                      },
                    },
                  }}
                  variant="outlined"
                >
                  {Object.keys(currencies).map((currencyCode, index) => (
                    <MenuItem key={currencyCode + index} value={currencyCode}>
                      {currencyCode}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {companyData["fin_year"] === "0" ||
              companyData["fin_year"] === "" ? null : (
                <>
                  <div className="company__fin-year-h company__heading">
                    Year:
                  </div>
                  <div className="company__fin-year-content company__text-content">
                    <CalendarMonthSharpIcon />
                    {companyData["fin_year"]?.includes("-")
                      ? companyData["fin_year"]?.split("-")[0] +
                        "-" +
                        companyData["fin_year"]?.split("-")[1].slice(-2)
                      : companyData["fin_year"]}
                  </div>
                </>
              )}
            </div>
          )}

          {companyData["sales"] === "0" ||
          companyData["sales"] === "" ? null : (
            <div className="company__sales">
              <div className="company__sales-h company__heading">
                Sales
                <Tooltip
                  title={
                    <span className="info-span">
                      Total company sales for the financial year
                    </span>
                  }
                  size="small"
                  className="tooltip"
                >
                  <IconButton size="small">
                    <HelpOutlineIcon size="small" />
                  </IconButton>
                </Tooltip>
              </div>

              <div className="company__sales-content company__text-content">
                <EqualizerIcon />
                <span className="fin-number">
                  {convertAndDisplaySales(
                    companyData["sales"],
                    companyData["country_code"],
                    selectedCurrency
                  )}
                  <span className="fin-number-zero">.000</span>
                </span>
              </div>
            </div>
          )}

          {companyData["add_finance"] === "0" ||
          companyData["add_finance"] === "" ||
          companyData["add_finance"] === 0 ? null : (
            <div className="company__add-finance">
              <div className="company__add-finance-h company__heading">
                {companyData["add_finance_type"] === "Bruttofortjeneste" ||
                companyData["add_finance_type"] === "gross_profit"
                  ? "Gross profit"
                  : companyData["add_finance_type"] === "zysk operacyjni" ||
                    companyData["add_finance_type"] === "Liikevoitto" ||
                    companyData["add_finance_type"] === "operational_profit"
                  ? "Operational profit"
                  : companyData["add_finance_type"] === "total-assets"
                  ? "Total assets"
                  : "Unknown"}

                {companyData["add_finance_type"] === "Bruttofortjeneste" ||
                companyData["add_finance_type"] === "gross_profit" ? (
                  <Tooltip
                    title={
                      <span className="info-span">
                        Gross profit is the company's revenue from sales minus
                        the cost of goods sold, before other expenses like
                        operating costs, taxes, and interest.
                      </span>
                    }
                    size="small"
                    className="tooltip"
                  >
                    <IconButton size="small">
                      <HelpOutlineIcon size="small" />
                    </IconButton>
                  </Tooltip>
                ) : companyData["add_finance_type"] === "zysk operacyjni" ||
                  companyData["add_finance_type"] === "Liikevoitto" ||
                  companyData["add_finance_type"] === "operational_profit" ? (
                  <Tooltip
                    title={
                      <span className="info-span">
                        Operational profit is the company's profit after
                        subtracting operating expenses from gross profit. It
                        shows how much the business earns from its main
                        activities, before taxes and interest.
                      </span>
                    }
                    size="small"
                    className="tooltip"
                  >
                    <IconButton size="small">
                      <HelpOutlineIcon size="small" />
                    </IconButton>
                  </Tooltip>
                ) : companyData["add_finance_type"] === "total-assets" ? (
                  <Tooltip
                    title={
                      <span className="info-span">
                        Total Assets represent the total value of all resources
                        owned by a company, including cash, inventory, and
                        property. This metric is vital for assessing financial
                        health and liquidity.
                      </span>
                    }
                    size="small"
                    className="tooltip"
                  >
                    <IconButton size="small">
                      <HelpOutlineIcon size="small" />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </div>
              <div className="company__add-finance-content company__text-content">
                <DataSaverOffIcon />
                <span className="fin-number">
                  {convertAndDisplaySales(
                    companyData["add_finance"],
                    companyData["country_code"],
                    selectedCurrency
                  )}
                  <span className="fin-number-zero">.000</span>
                </span>
              </div>
            </div>
          )}

          {companyData["profit"] === "0" ||
          companyData["profit"] === "" ||
          companyData["profit"] === 0 ? null : (
            <div className="company__profit">
              <div className="company__profit-h company__heading">
                Net profit
                <Tooltip
                  title={
                    <span className="info-span">
                      Net profit of the company for the financial year after
                      deducting all taxes and possible expenses.
                    </span>
                  }
                  size="small"
                  className="tooltip"
                >
                  <IconButton size="small">
                    <HelpOutlineIcon size="small" />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="company__profit-content company__text-content">
                <PaymentsSharpIcon />
                <span className="fin-number">
                  {/* {parseInt(companyData['profit'])?.toLocaleString('en-US').replace(/,/g, '.')} */}

                  {convertAndDisplaySales(
                    companyData["profit"],
                    companyData["country_code"],
                    selectedCurrency
                  )}
                  <span className="fin-number-zero">.000</span>
                </span>
                {/* <span className='fin-currency'>
                                                {currenciesArray[0].name}
                                            </span> */}
              </div>
            </div>
          )}
        </div>
      ) : (companyData["sales"] !== "0" && companyData["sales"] !== "") ||
        (companyData["profit"] !== "0" && companyData["profit"] !== "") ||
        (companyData["add_finance"] !== "0" &&
          companyData["add_finance"] !== "") ? (
        <Fade
          unmountOnExit={true}
          in={true}
          timeout={{ enter: 400, exit: 200 }}
        >
          <span className="wide-loader-item wide-loader-item--full">
            <Stack sx={{ width: "100%", color: "grey.500" }} spacing={4}>
              <LinearProgress
                color="primary_grey_light_light"
                sx={{
                  height: "25px",
                  borderRadius: "2px",
                }}
              />
            </Stack>
          </span>
        </Fade>
      ) : null}
    </>
  );
};

export default CompanySales;
