import Cookies from "universal-cookie";
import * as XLSX from "xlsx";
import { foldersIconsArray } from "../constants";

/**
 * Download the data as an Excel file
 * @param {*} dataIn
 * @param {*} sensor
 */
export function downloadExcel(dataIn, sensor) {
  const ws = XLSX.utils.json_to_sheet(dataIn);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const FileName = `WineDistributors_${sensor}.xlsx`;
  XLSX.writeFile(wb, FileName);
}

/**
 * Create a CSV file and download it
 * @param {*} data
 * @param {*} headers
 * @param {*} sensor
 */
function createCSV(data, headers, sensor) {
  const quote = '"';
  const csvHeader = headers.join(";") + "\n";
  const csvBody = data
    .map((row) => quote + Object.values(row).join('";"') + quote)
    .join("\n");

  const hiddenElement = document.createElement("a");
  hiddenElement.href =
    "data:text/csv;charset=utf-8," + encodeURIComponent(csvHeader + csvBody);
  hiddenElement.target = "_blank";
  hiddenElement.download = `${sensor}.csv`;
  hiddenElement.click();
}

/**
 * Format the headers of the CSV file
 * @param {*} data
 * @param {*} headerMappings
 */
function formatHeaders(data, headerMappings) {
  return Object.keys(data[0]).map((header) => {
    return headerMappings[header]
      ? headerMappings[header].toUpperCase()
      : header.toUpperCase();
  });
}

/**
 * Download the data as a CSV file
 * @param {*} data
 * @param {*} sensor
 */
export function downloadCSV(data, sensor) {
  const headerMappings = {
    tel: "Telephone",
    prod_country: "Imported_Wines",
    id: "code",
  };

  const formattedData = JSON.parse(
    JSON.stringify(data)
      .replaceAll("&quot;", "")
      .replaceAll("&#039;", "'")
      .replaceAll("&amp;", "&")
  );

  const headers = formatHeaders(data, headerMappings);
  createCSV(formattedData, headers, sensor);
}
/**
 * Download the emails as a CSV file
 * @param {*} data
 * @param {*} sensor
 */
export function downloadEmailsCSV(data, sensor) {
  if (data.length > 0) {
    const headerMappings = {
      id: "code",
      ID: "code",
    };

    const headers = formatHeaders(data, headerMappings);
    createCSV(data, headers, sensor);
  }
}

/**
 * Get token from cookies
 */
export function getToken() {
  if (process.env.REACT_APP_SERVER === "local") {
    return process.env.REACT_APP_TEST_TOKEN;
  }
  const cookies = new Cookies();
  return cookies.get("app_vinaty_token");
}

/**
 * Finds icon in the array of icons
 * @param {*} item
 * @returns
 */
export function findIcon(item) {
  for (let i = 0; i < foldersIconsArray.length; i++) {
    if (foldersIconsArray[i].name === item.icon) {
      var icon = foldersIconsArray[i];
      return (
        <icon.icon
          size={"small"}
          sx={{ color: item.color, fontSize: "24px" }}
        />
      );
    }
  }
}

export function toggleSidebar() {
  var sidebar = document.getElementById("sidebar");
  var toggle = document.getElementById("toggle-sidebar");

  if (!sidebar.classList.contains("sidebar-active")) {
    sidebar.classList.add("sidebar-active");
    sidebar.style.left = "0";
    toggle.classList.remove("toggle-sidebar--active");
  } else {
    sidebar.classList.remove("sidebar-active");
    sidebar.style.left = "-500px";
    toggle.classList.add("toggle-sidebar--active");
  }
}

export function convertDate(dateIn) {
  var dateOut = 'No date';
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (dateIn !== '0') {
    var year = "'" + dateIn.substring(2,4);
    var month = months[dateIn.substring(5, 7).replace(/^0+/, '') - 1];
    var time = dateIn.substring(dateIn.length - 8, dateIn.length - 3);
    var day = dateIn.substring(8, 10);
    var dateOut = month + " " + day + ", " + year;
  }

  return dateOut;
}

export function createLinkedinLink(link) {
  var urlReady = "";
  var linkArr = link.split(":");
  for (let i = 0; i < linkArr?.length; i++) {
    if (linkArr[i]?.includes("linkedin")) {
      urlReady = "https://linkedin" + linkArr[i].split("linkedin")[1];
    }
  }
  return urlReady;
}