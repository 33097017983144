import React from "react";
import { convertDate } from "../../../utils";
import { Fade, LinearProgress, Stack } from "@mui/material";
import { CopyIcon } from "../../CopyIcon";

const CompanyName = ({fetching, companyData}) => {
  return (
    <>
      {fetching ? (
        <div className={"company__section-name fade-appear"}>
          <div className="company__updated">
            {companyData?.updated_timestamp !== "" ? (
              <span className="company__container-date">
                Upd. on {convertDate(companyData?.updated_timestamp)}
              </span>
            ) : (
              <span className="no-info">No info</span>
            )}
          </div>

          <div className="company__trade-name">
            <div className="company__name-h company__heading">Trade name</div>
            <div className="company__name-content company__text-content">
              {companyData["name"]}
            </div>
          </div>

          {companyData["legal_name"] === "0" ? null : (
            <div className="company__legal-name">
              <div className="company__name-h company__heading">Legal name</div>
              <div className="company__name-content company__text-content">
                {companyData["legal_name"]}
                <CopyIcon left value={companyData.legal_name} />
              </div>
            </div>
          )}
        </div>
      ) : companyData["name"] !== "0" ? (
        <Fade
          unmountOnExit={true}
          in={true}
          timeout={{ enter: 400, exit: 200 }}
        >
          <span className="wide-loader-item wide-loader-item--full">
            <Stack sx={{ width: "100%", color: "grey.500" }} spacing={4}>
              <LinearProgress
                color="primary_grey_light_light"
                sx={{
                  height: "25px",
                  borderRadius: "2px",
                }}
              />
            </Stack>
          </span>
        </Fade>
      ) : null}
    </>
  );
};

export default CompanyName;
