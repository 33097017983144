import React, { useEffect, useState } from "react";

// Components
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import EditIcon from "@mui/icons-material/Edit";
import EditFolder from "./EditFolder";

// Functions and utils
import { findIcon } from "../../utils";
import { useFolders } from "../../hooks/FoldersContext";
import { useSaves } from "../../hooks/SavesContext";
import { useContent } from "../../hooks/ContentContext";

const FoldersList = () => {
  // States

  // ID edited Folder
  const [folderEdit, setFolderEdit] = useState(0);
  const [folderEditOpened, setFolderEditOpened] = useState(false);
  const { setFilters, filters, clearChosenItems } = useContent();
  const { savedFolders, smFoldersOpened } = useFolders();
  const { savesOpened, setSavesOpened } = useSaves();

  useEffect(() => {
    if (savesOpened) {
      setFolderEditOpened(false);
    }
  }, [savesOpened]);

  // Function to Open Creation of Folder
  function openCreateFolder(folderId) {
    setSavesOpened(false);
    setFolderEdit(folderId);
    setFolderEditOpened(true);
  }

  function closeFolderEdit() {
    setFolderEditOpened(false);
  }

  function chooseFolder(id_folder) {
    setFilters((prevState) => ({
      ...prevState,
      folder: filters["folder"] === id_folder ? "" : id_folder,
    }));
    clearChosenItems();
    if (savesOpened) {
      setSavesOpened(false);
    }
  }

  return (
    <>
      <Collapse className="folders" in={smFoldersOpened}>
        <div className="folders__folders">
          {savedFolders.length < 1 ? (
            "You don't have folders yet"
          ) : (
            <div className="folders__draggable-context">
              {savedFolders.map((item, index) => {
                return (
                  <div key={item.folder_id} style={{ position: "relative" }}>
                    <div
                      onClick={() => chooseFolder(item.folder_id)}
                      className={
                        "folders__item" +
                        (filters["folder"] === item.folder_id
                          ? " folder--active"
                          : "")
                      }
                      data-folder={item.folder_id}
                    >
                      {findIcon(item)}
                      <span className="folders__item-name">{item.name}</span>
                    </div>

                    <span
                      onClick={() => openCreateFolder(item.folder_id)}
                      className="folders__edit"
                    >
                      <EditIcon />
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {savedFolders.length < 5 ? (
          <>
            <Button
              size={"small"}
              sx={{
                textTransform: "none",
                marginTop: "20px",
                width: "74%",
                marginLeft: "14%",
                borderRadius: "2px",
              }}
              className="sidebar__create-folder"
              color="primary_grey_light"
              startIcon={<CreateNewFolderIcon />}
              onClick={() => openCreateFolder(0)}
              variant="contained"
            >
              Create Folder
            </Button>

            <span className="folders__span">Up to 5 folders</span>
          </>
        ) : (
          <span className="folders__span">
            You have reached the maximum number of folders
          </span>
        )}
      </Collapse>

      <EditFolder
        folderId={folderEdit}
        opened={folderEditOpened}
        toggleVisibility={closeFolderEdit}
      />
    </>
  );
};

export default FoldersList;
