import { Button } from "@mui/material";
import React from "react";
import NotFoundImage from "../../assets/vinaty-not-found.png";

const ExpiredAccess = () => {
  return (
    <div className="results__expired">
      <p className="limit__heading">Your Access Has Expired</p>

      <p className="limit__text">
        You can still use your Download folder without restrictions. Please
        renew your subscription or contact us support@vinaty.com
      </p>

      <div className="limit__buttons">
        <Button
          variant="contained"
          size="medium"
          // startIcon={<AccountBoxIcon />}
          href="https://vinaty.com/account/"
          target="_blank"
        >
          Get Full access
        </Button>
      </div>

      <img src={NotFoundImage} width="400" height="229" alt="Not Found" />
    </div>
  );
};

export default ExpiredAccess;
