import React from "react";

// Material
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import { useSaves } from "../hooks/SavesContext";
import { handleDeleteSave } from "../actions/searches";
import { convertDate } from "../utils";
import { useContent } from "../hooks/ContentContext";

export default function UserSaves() {
  const { setFilters, setTextSearch, setTextSearchParams } = useContent();
  const { savedSearches, setSavedSearches, savesOpened, setSavesOpened } =
    useSaves();

  function deleteSave(save_id) {
    handleDeleteSave(save_id).then((data) => {
      setSavedSearches(data);
    });
  }

  // Load chose Save
  function loadThisSave(save_id) {
    setSavesOpened(false);
    var arraySave;
    for (let i = 0; i < savedSearches.length; i++) {
      if (savedSearches[i].save_id === save_id) {
        var hex = savedSearches[i].content;

        // decode hex
        var length = hex.length / 2;
        var result = new Uint8Array(length);
        for (var i2 = 0; i2 < length; ++i2) {
          result[i2] = parseInt(hex.slice(i2 * 2, i2 * 2 + 2), 16);
        }
        var string = new TextDecoder().decode(result);
        arraySave = JSON.parse(string);
      }
    }
    setFilters(arraySave.filters);
    setTextSearch(arraySave.text_search);
    setTextSearchParams(arraySave.text_search_params);
  }

  function closeSaves() {
    setSavesOpened(false);
  }

  return (
    <div
      className={
        "sidebar-absolute-slide" +
        (!savesOpened ? " sidebar-absolute-slide--active" : "")
      }
    >
      <div className="sidebar-absolute"></div>
      <div className="user-saves">
        <div className="saves-header">
          <p>List of your saves</p>
          <IconButton
            onClick={closeSaves}
            aria-label="close"
            size="medium"
            className="close-saves"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>

        {savedSearches != null && savedSearches.length > 0 ? (
          savedSearches?.map((item, index) => {
            return (
              <div className="user-saves__item" key={index}>
                <div className="user-saves__info">
                  <span className="user-saves__name">{item.name}</span>
                  <div className="user-saves__date-container">
                    <span className="user-saves__date">
                      {convertDate(item.timestamp)}
                    </span>
                  </div>
                </div>

                <div className="user-saves__buttons">
                  <IconButton
                    data-itemid={item.save_id}
                    onClick={() => deleteSave(item.save_id)}
                    color="red"
                    aria-label="delete"
                    size="small"
                    className="user-saves__delete"
                  >
                    <DeleteIcon />
                  </IconButton>

                  <Button
                    onClick={() => loadThisSave(item.save_id)}
                    size={"small"}
                    variant="contained"
                    color={"primary_grey_light"}
                    endIcon={<SendIcon />}
                    className="user-saves__save"
                  >
                    <span>Load Save</span>
                  </Button>
                </div>
              </div>
            );
          })
        ) : (
          <div className="user-saves__empty">No saved searches found</div>
        )}
      </div>
    </div>
  );
}
