import React, { useEffect, useState } from "react";

// Components
import NotFoundImage from "./../assets/vinaty-not-found.png";
import Loader from "./../hooks/Loader";

// Material

import Button from "@mui/material/Button";
import { useContent } from "../hooks/ContentContext";
import EmailInfoModal from "./EmailInfoModal";
import TableHeader from "./TableHeader";
import ExpiredAccess from "./ExpiredAccess";
import FreeTrialInfo from "./FreeTrialInfo";
import { DistribitorItem } from "./DistributorItem";
import { useUser } from "../hooks/UserContext";

export default function Content(props) {
  const {
    getCookie,
    currentFolder,
    setCurrentFolder,
    setResultIds,
    SingleDownload,
    changeBookmarkCounter,
    setBookmarksCounter,
    setModalDownload,
    currencies,
  } = props;

  const {
    clearFilters,
    filters,
    textSearch,
    textSearchParams,
    pageNumber,
    perPage,
    result,
    fetching,
  } = useContent();
  const { userPlan, userExpired, userViewsIds, setUserViewsIds, userDownloadIds } = useUser();

  const [requestParams, setRequestParams] = useState({
    filters: filters,
    time_filter: "0",
    text_search: textSearch,
    text_search_params: textSearchParams,
    per_page: perPage,
    page_number: pageNumber,
    user_expired: userExpired,
  });

  useEffect(() => {
    setRequestParams({
      ...requestParams,
      filters,
      text_search: textSearch,
      text_search_params: textSearchParams,
      page_number: pageNumber,
      per_page: perPage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, textSearch, textSearchParams, pageNumber, perPage]);

  // Status of toggle of helper in Type
  const [emailStatusInfoState, setEmailStatusInfoState] = useState(false);

  // Status email info
  function openEmailStatusInfo(event) {
    event.stopPropagation();
    setEmailStatusInfoState((prevState) => !prevState);
  }

  useEffect(() => {
    if (result !== undefined) {
      setCurrentFolder(result?.folders);
      setBookmarksCounter(result?.bookmark_counter);
    }
    var tempResultIds = [];
    if (result !== undefined) {
      for (let i = 0; i < result?.distributors.length; i++) {
        tempResultIds.push(result?.distributors[i].id);
      }
      setResultIds(tempResultIds);
    }
    // console.log(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  return (
    <div>
      {fetching ? (
        <div>
          <Loader />
        </div>
      ) : result?.distributors?.length > 0 ? (
        <>
          <EmailInfoModal
            opened={emailStatusInfoState}
            close={() => setEmailStatusInfoState(false)}
          />
          <TableHeader />
          {result?.distributors.map((item, id) => {
            return (
              <DistribitorItem
                item={item}
                id={id}
                key={id}
                downloaded={userDownloadIds?.includes(item?.id)}
                viewed={userViewsIds?.includes(item?.id)}
                getCookie={getCookie}
                userPlan={userPlan}
                openEmailStatusInfo={openEmailStatusInfo}
                currentFolder={currentFolder}
                pageNumber={pageNumber}
                SingleDownload={SingleDownload}
                changeBookmarkCounter={changeBookmarkCounter}
                setModalDownload={setModalDownload}
                currencies={currencies}
                setUserViewsIds={setUserViewsIds}
              />
            );
          })}
        </>
      ) : userExpired && result.folders !== "0" ? (
        <ExpiredAccess />
      ) : !userPlan && result.folders !== "0" && result.folders !== "" ? (
        <FreeTrialInfo />
      ) : (
        <div className="results__nothing">
          <p>Please try change search filters or change the folder</p>
          {result?.result_counted < result?.counters?.total ? (
            <Button
              variant="contained"
              size="large"
              className="nothing__clear"
              onClick={clearFilters}
            >
              Clear All Filters
            </Button>
          ) : null}

          <img src={NotFoundImage} width="600" height="339" alt="Not Found" />
        </div>
      )}
    </div>
  );
}
