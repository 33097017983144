import React from "react";
import Button from "@mui/material/Button";
import ReplayIcon from "@mui/icons-material/Replay";
import { useContent } from "../../hooks/ContentContext";

const PaginationTotalResults = (props) => {
  const { clearFilters, result } = useContent();
  const foundResult =
    result?.result_counted !== undefined ? result.result_counted : false;

  const totalResult =
    result?.counters?.total !== undefined ? result?.counters?.total : false;

  return (
    <div className="pagination__total-results">
      <div
        className={
          "pagination__clear" +
          (foundResult < totalResult ? " pagination__clear--active" : "")
        }
      >
        <Button
          onClick={() => clearFilters()}
          variant="contained"
          startIcon={<ReplayIcon />}
          color={"primary_grey_light"}
          size={"small"}
          className="pagination__button-clear"
        >
          Clear Filters
        </Button>
      </div>

      <div className="pagination__block">
        {totalResult !== false ? (
          <>
            Found {foundResult} distributor{foundResult > 1 ? "s" : ""}
          </>
        ) : (
          "loader"
        )}
      </div>
    </div>
  );
};

export default PaginationTotalResults;
