import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: "rgb(197, 180, 196)",
            color: "rgb(245, 245, 245)",
          },
        },
      },
    },
  },
  typography: {
    fontFamily: [
      "Inter",
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      main: "#8d5289",
      light: "#a86aa4",
      dark: "#7d4979",
    },
    primary_light: {
      main: "#c093be",
      light: "#b785b4",
      dark: "#b785b4",
      contrastText: "#fff",
    },
    primary_grey_light: {
      main: "rgb(170, 132, 168)",
      light: "#c6b5c6",
      dark: "rgb(161, 111, 159)",
      contrastText: "#fff",
    },
    primary_grey_light_light: {
      main: "rgb(230, 218, 229)",
      light: "rgb(237, 232, 237)",
      dark: "rgb(204, 184, 204)",
      contrastText: "#fff",
    },
    primary_red_light: {
      main: "rgb(177, 74, 95)",
      light: "rgb(202, 100, 121)",
      dark: "rgb(161, 59, 79)",
      contrastText: "#fff",
    },
    primary_icon_light: {
      main: "#a86aa4",
      light: "#e9cee8",
      dark: "#8d5289",
    },
    secondary: {
      main: "#b9b3cb",
      light: "#ced6ed",
      dark: "#8f9abd",
    },
    secondary_light: {
      dark: "#CEE2F5",
      light: "#fff",
      main: "#E4F0FB",
    },
    blue_button_link: {
      dark: "rgb(228, 239, 249)",
      light: "#fff",
      // main: 'rgb(237, 244, 253)',
      main: "rgb(243, 247, 250)",
    },
    insta_light: {
      dark: "#e9d6df",
      light: "#f1e3ea",
      main: "#f1e3ea",
    },
    x_light: {
      dark: "#e3e3e3",
      light: "#efefef",
      main: "#efefef",
    },
    linkedin_light: {
      dark: "#ee557b",
      light: "#ee557b",
      main: "#ee557b",
    },
    inactive: {
      main: "#d0cbd0",
      dark: "#d0cbd0",
      light: "#d0cbd0",
    },
    red: {
      main: "rgb(198 18 18 / 45%)",
      dark: "rgb(198 18 18 / 75%)",
      light: "rgb(198 18 18 / 20%)",
    },
    red_dark: {
      main: "rgb(198 18 18 / 58%)",
      dark: "rgb(198 18 18 / 70%)",
      light: "rgb(198 18 18 / 50%)",
    },
    green_dark: {
      main: "rgb(98 156 29 / 58%)",
      dark: "rgb(98 156 29 / 70%)",
      light: "rgb(98 156 29 / 50%)",
    },
    green_menu: {
      main: "rgb(115, 195, 115)",
      dark: "rgb(103, 185, 103);",
      light: "rgb(170, 238, 170)",
      contrastText: "#fff",
    },
    white: {
      main: "#f3f3f3",
      dark: "#dbdbdb",
      light: "#ffffff",
    },
    opener_locked: {
      main: "rgb(120, 120, 120);",
      dark: "rgb(73, 73, 73)",
      light: "rgb(204, 204, 204)",
    },
  },
});
