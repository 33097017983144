import { Fade, LinearProgress, Stack } from "@mui/material";
import React from "react";
import { FaWineBottle } from "react-icons/fa6";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { AiOutlineFlag } from "react-icons/ai";

const CompanyYear = ({ companyData, fetching }) => {
  return (
    <>
      {fetching ? (
        <div className={"company__section-ye fade-appear"}>
          {companyData["year"] && companyData["year"] !== "0" && (
            <div className="company__year">
              <div className="company__year-h company__heading">Founded</div>
              <div className="company__year-content company__text-content">
                <AiOutlineFlag />
                {companyData["year"]}
              </div>
            </div>
          )}

          {companyData["employees"] && companyData["employees"] !== "0" && (
            <div className="company__employees">
              <div className="company__employees-h company__heading">
                Employees
              </div>
              <div className="company__employees-content company__text-content">
                <SupervisorAccountIcon />
                {(() => {
                  const emp = parseInt(companyData["employees"]);
                  if (emp < 11) return "1-10";
                  if (emp < 51) return "11-50";
                  if (emp < 201) return "51-200";
                  if (emp < 501) return "201-500";
                  if (emp < 1001) return "501-1000";
                  if (emp < 5001) return "1001-5000";
                  if (emp < 10001) return "5001-10000";
                  return "10000+";
                })()}
              </div>
            </div>
          )}

          {companyData["product_qty"] && companyData["product_qty"] !== "0" && (
            <div className="company__product-qty">
              <div className="company__product-qty-h company__heading">
                Wines
              </div>
              <div className="company__product-qty-content company__text-content">
                <FaWineBottle />
                {companyData["product_qty"]}
              </div>
            </div>
          )}
        </div>
      ) : (
        (companyData["employees"] !== "0" || companyData["year"] !== "0") && (
          <Fade
            unmountOnExit={true}
            in={true}
            timeout={{ enter: 400, exit: 200 }}
          >
            <span className="wide-loader-item wide-loader-item--full">
              <Stack sx={{ width: "100%", color: "grey.500" }} spacing={4}>
                <LinearProgress
                  color="primary_grey_light_light"
                  sx={{
                    height: "25px",
                    borderRadius: "2px",
                  }}
                />
              </Stack>
            </span>
          </Fade>
        )
      )}
    </>
  );
};

export default CompanyYear;
