import React, { useState } from "react";
import BusinessIcon from "@mui/icons-material/Business";
import { ImLocation } from "react-icons/im";
import { Button, Fade, LinearProgress, Stack } from "@mui/material";

const CompanyAddress = ({ fetching, companyData, id }) => {
  const [showBranches, setShowBranches] = useState(7);
  
  function showBranchesFunc(lngth) {
    if (showBranches !== 7) {
      setShowBranches(7);
    } else {
      setShowBranches(lngth);
    }
  }
  
  return (
    <>
      {fetching ? (
        <div className={"company__section-address fade-appear"}>
          <div className="company__address address-trade">
            <div className="company__address-h company__heading">Address</div>

            <div className="company__address-content company__text-content">
              <ImLocation />
              {companyData["country"]}
              {companyData["country"] ? ", " : ""}
              {companyData["province"]?.split(",")[0]}
              {companyData["province"] ? ", " : ""}
              {companyData["city"]}
              {(companyData["province"] || companyData["city"]) &&
              companyData["address"] !== "0"
                ? ", "
                : " "}
              {companyData["address"]}
            </div>
            {companyData["province"]?.split(",").length > 1 ? (
              <div className="company__address-branches">
                <span className="address-branches__h">Branches:</span>
                {companyData["province"]?.split(",")?.map((branch, ibranch) => {
                  return (
                    <>
                      {ibranch < showBranches ? (
                        <span
                          key={"province" + ibranch + id}
                          className="address-branches__span"
                        >
                          {branch}
                          {ibranch !== showBranches - 1 &&
                          companyData["province"]?.split(",").length - 1 !==
                            ibranch
                            ? ", "
                            : ""}
                        </span>
                      ) : null}
                    </>
                  );
                })}

                {companyData["province"]?.split(",").length > 7 ? (
                  <Button
                    className="persons__open"
                    color="primary_grey_light"
                    size="small"
                    variant="outlined"
                    onClick={() =>
                      showBranchesFunc(
                        companyData["province"]?.split(",").length
                      )
                    }
                    // endIcon={<ArrowForwardIosIcon />}
                    sx={{
                      borderRadius: "1px",
                      paddingLeft: "3px",
                      paddingRight: "3px",
                      height: "16px",
                      marginLeft: "4px",
                      textTransform: "none",
                      fontSize: "0.72rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {showBranches !== 7
                      ? "Hide"
                      : "+" +
                        (companyData["province"]?.split(",").length -
                          showBranches) +
                        " more"}
                  </Button>
                ) : null}
              </div>
            ) : null}
          </div>

          {companyData["legal_address"] === "0" ? null : (
            <div className="company__address address-legal">
              <div className="company__address-h company__heading">
                Legal address
              </div>
              <div className="company__address-content company__text-content">
                <BusinessIcon /> {companyData["legal_address"]}
              </div>
            </div>
          )}
        </div>
      ) : companyData["country"] || companyData["legal_address"] !== "0" ? (
        <Fade
          unmountOnExit={true}
          in={true}
          timeout={{ enter: 400, exit: 200 }}
        >
          <span className="wide-loader-item wide-loader-item--full">
            <Stack sx={{ width: "100%", color: "grey.500" }} spacing={4}>
              <LinearProgress
                color="primary_grey_light_light"
                sx={{
                  height: "25px",
                  borderRadius: "2px",
                }}
              />
            </Stack>
          </span>
        </Fade>
      ) : null}
    </>
  );
};

export default CompanyAddress;
