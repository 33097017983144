import React, { useEffect, useState } from "react";
import PhoneData from "../../PhoneData";
import { Fade, LinearProgress, Stack } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const CompanyPhone = ({ fetching, companyData }) => {
  const [phones, setPhones] = useState([]);
  useEffect(() => {
    if (companyData) {
      setPhones(companyData["phones"]);
    }
  }, [companyData]);

  return (
    <>
      {fetching ? (
        <div className="company__section company__section-phones fade-appear">
          <div className="company__phones-h company__heading">Phones</div>
          {Array.isArray(phones) &&
          phones.length > 0 &&
          phones.some((phoneDataState) =>
            phoneDataState.isvalid.includes("Valid")
          ) ? (
            <div className={"btm-tel__array"}>
              <div className={"btm-tel__array"}>
                <PhoneData itemid={companyData.id} phones={phones} />
              </div>
            </div>
          ) : (
            <span className="single-bottom-info__no-info no-info--phone">
              <LocalPhoneIcon />
              No phones found
            </span>
          )}
        </div>
      ) : (
        <Fade
          unmountOnExit={true}
          in={true}
          timeout={{ enter: 400, exit: 200 }}
        >
          <span className="wide-loader-item wide-loader-item--full">
            <Stack sx={{ width: "100%", color: "grey.500" }} spacing={4}>
              <LinearProgress
                color="primary_grey_light_light"
                sx={{
                  height: "25px",
                  borderRadius: "2px",
                }}
              />
            </Stack>
          </span>
        </Fade>
      )}
    </>
  );
};

export default CompanyPhone;
