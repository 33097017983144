import React from "react";

import { useFolders } from "../../hooks/FoldersContext";
import { handleAddItemToFolder } from "../../actions/folders";
import FoldersButton from "./FoldersButton";
import { useContent } from "../../hooks/ContentContext";

const FoldersMultipleAdd = (props) => {
  const { contentMenuHide } = props;

  const { setSavedFolders, setSmFoldersOpened } = useFolders();
  const { chosenItems } = useContent();
  /**
   * Добавяет или удаляет пользователя из папки
   * @param {any} folder
   * @param {"add" | "remove"} action
   */
  async function addItemToFolder(folder, action) {
    const params = {
      action: action,
      item_id: chosenItems,
      folder_id: folder.folder_id,
    };
    const data = await handleAddItemToFolder(params, action, folder);
    if (data) {
      setSavedFolders(data);
    }
    setSmFoldersOpened(true);
  }

  return (
    <div className="folder-multi-actions">
      <FoldersButton
        action={"add"}
        chosenItems={chosenItems}
        contentMenuHide={contentMenuHide}
        addItemToFolder={addItemToFolder}
      />

      <FoldersButton
        action={"remove"}
        chosenItems={chosenItems}
        contentMenuHide={contentMenuHide}
        addItemToFolder={addItemToFolder}
      />
    </div>
  );
};

export default FoldersMultipleAdd;
